.tripadvisor{
    
    .top{
        .background{
            background-image:url('../img/tripadvisor_hero_1.jpg');
        }
    }

    .infoTwo{
        .background{
            background-image:url('../img/tripadvisor_hero_2.jpg');
        }
    }

    .highlight{
        color:@taGreen !important;
    }
}