.petrJohn{
	.top{
		.background{
			background-image:url('../img/petrjohn_casestudy_bg@2x.jpg');
		}
	}
	
	.infoThree{
		.background{
			background-color: @darkerGrey;
		}

		.content{
			position: relative;
			background-color: @darkerGrey;
		}
	}

	.infoTwo{
		.background{
			background-image:url('../img/petrjohn_header_bg@2x.jpg');
		}
	}

	.press{
		.pressContainer{
			.pressItem{
				.title{
					h2{
						color:#ff3f33;
					}
				}
			}
		}
	}

	.featured{
		color: white;
		box-sizing:border-box;
			position: relative;

		.content{
			display: table;
			.innerContent{
				display: table-cell;
				vertical-align: middle;

				.text{
					margin: 0 auto;
					width: 240px;
					padding-top: 50px;
					padding-bottom: 50px;
					width: 100%;

					@media @768{
						padding-top: 80px;
						padding-bottom: 80px;
					}

					@media @1366{
						padding-top: 100px;
						padding-bottom: 100px;
					}

					h1{
						font-family: DINCondensed-Bold;
						text-transform: uppercase;
					    font-size: 35px;
					    text-align: left;
					    letter-spacing: 3px;
						line-height: 30px;
		    			padding-left: 50px;
						padding-right: 50px;

						@media @1024{
							padding-right: 100px;
							padding-left: 100px;
						}

						@media @1366{
							padding-right: 250px;
							padding-left: 250px;
						}
					}

					p{
		   				.dinLight;
		   				font-size: 14px;
		   				padding-left: 50px;
						padding-right: 50px;

						@media @768{
							font-size: 18px;
						}

						@media @1024{
							padding-right: 100px;
							padding-left: 100px;
						}

						@media @1366{
							padding-right: 250px;
							padding-left: 250px;
						}
					}

					.dropdownCopy{
						text-align: center;
					    text-transform: uppercase;
					    font-size: 25px;
					    letter-spacing: 3px;
					    /* line-height: 72px; */
					    margin-top: 20px;
					}


					.dropdown{
						text-align: center;
   						//padding-left: 50px;
   						margin-top: 5px;

						@media @1024{
							//padding-left: 150px;
						}

						@media @1366{
							//padding-left: 250px;
						}

						select{
							-webkit-appearance: none;
						    -moz-appearance: none;
						    border: 0;
						    background-color: white;
						    color: @darkerGrey;
						    border-radius: 10px;
						    text-transform: uppercase;
						    padding: 10px;
						    width: 250px;
						    font-family: DINCondensed-Bold;
						    font-weight: 800;
						    font-size: 15px;
						    letter-spacing: 1px;
						    cursor: pointer;
						    background-image: url(../img/drop@2x.png);
						    background-size: 16px 16px;
						    background-repeat: no-repeat;
						    background-position: 220px 10px;
						    padding-right: 40px;
						}
					}
				}

				.videoPlayer{
					//margin: auto;
				    position: relative;
				    //padding-left: 50px;
				    width: 250px;
				    margin: auto;
				   margin-top: 50px;
				   height: 141px;

				    @media @375{
				    	width: 300px;
				    	height: 169px;
				    }

				    @media @500{
				    	//padding-left: 50px;
				    	width: 425px;
				    	height: 240px;
				    }

				    @media @1024{
						//padding-left: 150px;
						width: 720px;
						height: 405px;
					}

					@media @1366{
						width: 1000px;
						height: 563px;
						//padding-left: 250px;
					}

					.overlayVideo{
						position: absolute;
						top: 0;
						bottom: 0;
						//left: 50px;
						left: 0;
						right: 0;
						margin: 0 auto;
						cursor: pointer;

						@media @1024{
							//left: 150px;
						}

						@media @1366{
							//left: 250px; 
						}

						.bg{
							background-color: black;
							opacity: 0.4;
							position: absolute;
							width: 100%;
							height: 100%;
						}

						.playBtn{
							.at2x('../img/play_btn.png', 128px, 128px);
							.cover;
							.ccnr;
							width: 80px;
							height: 80px;
							margin: auto;
							position: absolute;
							z-index: 2;
							top: 0;
						    bottom: 0;
						    left: 0;
						    right: 0;
						}
					}
				}

				.icon{
					font-size: 100px;
   					margin-left: 15px;
   					margin-bottom: 15px;
   					line-height: 0px;
   					opacity: 0.8;

   					@media @768{
   						font-size: 190px;
    					position: relative;
    					float: right;
    					margin-left: 0px;
    					margin-bottom: 0px;
    					margin-right: 100px;
   					}
				}
			}
		}
	}
}