.legendOfYou{
    
    .top{
        .background{
            background-image:url('../img/howitworks_hero_destiny.jpg');
        }
    }

    .infoTwo{
        .background{
            background-image:url('../img/destiny_mid_cs.jpg');
        }
    }
    
    .infoThree{
        .background{
            background-image:url('../img/destiny_mid_casestudy.jpg');
        }
    }

    .media{
        .mediaContainer{
            .imageWrapper{
                display: none;
            }   
        }
    }

    .press{
    	.pressContainer{
    		.pressItem{
    			.title{
    				h2{
    					color: #C93030;
    				}
    			}
    		}	
    	}
    }

    .stats{
    	.statsContainer{
    		.statsItem{
    			.headline{
    				span{
    					color:#C93030;
    				}
    			}
    		}
    	}
    }
}