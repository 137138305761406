.vzxmas{
    
    .top{
        .background{
            background-image:url('../img/verizon_hero_1.jpg');
        }
    }

    .infoTwo{
        .background{
            background-image:url('../img/verizon_hero_2.jpg');
        }
        .content{
            .innerContent{
                .text{

                    p{
                        font-size: 11px;
                        line-height: 15px;

                        @media @768{
                            font-size: 16px;
                            line-height: 18px;
                            padding-left: 50px;
                            padding-right: 50px;
                        }

                        @media @1024{
                            font-size: 18px;
                            line-height: 22px;
                        }

                        @media @1366{
                            padding-left:200px;
                            padding-right:200px;
                        }
                    }
                }
            }
        }
    }

    .highlight{
        color:#00a680 !important;
    }

    .media{
        .mediaContainer{
            .imageWrapper{
                display: block;
                
                .thumbnail{
                    display: inline-block;
                    width:49.9%;
                    .bg{
                        width:49.9%;
                        background-size: cover;
                        background-position: top center;
                    }

                    .overlay{
                        width:49.9%;
                    }
                }                

                .first{
                    .bg{
                        background-image:url('../img/girl_thumbnail_casestudy.jpg');
                    }
                }

                .second{
                    .bg{
                        background-image:url('../img/grandma_thumbnail_casestudy.jpg');
                    }
                }

                .third{
                    display: none;
                }

                .fourth{
                    display: none;
                }
            }   
        }
    }
}