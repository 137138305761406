.media{
	
	.item;

	background-color: @darkerGrey;
	
	color:white;
	text-align: center;

	.mediaContainer{
			padding-bottom: 20px;

		@media @768{
			padding-bottom: 50px;
		}

		@media @1366{
			padding-bottom: 100px;
		}
		.videoPlayer{
			//margin: auto;
		    position: relative;
		    //padding-left: 50px;
		    width: 250px;
		    height: 140px;
		    margin: 0 auto;
		    margin-top: 50px;
		    cursor: pointer;

		    @media @375{
		    	height: 168px;
		    	width: 300px;

		    }

		    @media @500{
		    	//padding-left: 50px;
		    	height: 239px;
		    	width: 425px;
		    }

		    @media @1024{
				//padding-left: 150px;
				height: 405px;
				width: 720px;
			}

			@media @1366{
				//padding-left: 250px;
				height: 562px;
				width: 1000px;
			}

			.overlayVideo{
				position: absolute;
				top: 0;
				bottom: 0;
				//left: 50px;
				left: 0;
				right: 0;
				margin: 0 auto;
				cursor: pointer;
				
				@media @1024{
					//left: 150px;
				}

				@media @1366{
					//left: 250px; 
				}

				.bg{
					background-color: black;
					opacity: 0.4;
					position: absolute;
					width: 100%;
					height: 100%;
					.ccnr;
					top: 0;
					z-index: 2;
				}

				.poster{
					position: absolute;
					top:0;

					img{
						height: 100%;
    					width: 100%;
					}
				}

				.text{
					position: relative;
					height: 100%;
					margin: 0 auto;

					p{
						color:white;
						text-transform: uppercase;
						font-size: 16px;
						line-height: 16px;
						letter-spacing: 2px;
						text-align: center;
						z-index: 3;
						position: absolute;
						margin: auto 0;
						top: 90px;
						bottom: 0;
						left: 0;
						right: 0;
						height: 23px;

						@media @1024{
							font-size: 25px;
							line-height: 24px;
							top: 130px;
						}
					}
				}

				.playBtn{
					.at2x('../img/play_btn.png', 128px, 128px);
					.cover;
					.ccnr;
					width: 60px;
					height: 60px;
					margin: auto;
					position: absolute;
					z-index: 3;
					top: 0;
				    bottom: 10px;
				    left: 0;
				    right: 0;
				    pointer-events: none;

				    @media @1024{
				    	width: 80px;
						height: 80px;
				    }
				}
			}
			.videoHolder{
				height: 100%;
				width: 100%;
			}
		}

		.imageWrapper{
			width: 300px;
			margin: 0 auto;
			position: relative;
			margin-top: 20px;

			@media @500{
				width:425px;
			}

			@media @1024{
				width: 720px;
			}

			@media @1366{
				width: 1000px;
			}

			.thumbnail{
				width: 33.3%;
				height: 100px;
				cursor: pointer;
				pointer-events: all;

				@media @1024{
				    height: 200px;
				}

				.overlay{
					background-color: black;
				    opacity: 0.5;
				    width: 33.3%;
				    height: 100px;
				    position: absolute;
				    z-index: 2;
				    pointer-events: none;

				    @media @1024{
				    	height: 200px;
				    }
				}

				.bg{
					.ccnr;
					width: 33.3%;
				    height: 100px;
				    z-index: 1;
				    position: absolute;
				    pointer-events: none;

				    @media @1024{
				    	height: 200px;
				    }
				}

				.text{
					position: relative;
					height: 100%;
					margin: 0 auto;
				    pointer-events: none;

					p{
						color:white;
						text-transform: uppercase;
						font-size: 18px;
						line-height: 16px;
						letter-spacing: 2px;
						text-align: center;
						z-index: 3;
						position: absolute;
						margin: auto 0;
						top: 0;
						bottom: 0;
						left: 0;
						right: 0;
						height: 23px;
				   		pointer-events: none;

						@media @1024{
							font-size: 25px;
							line-height: 24px;
						}
					}
				}
			}
		}	
	}
}