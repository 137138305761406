.dart{

    .top{
        .background{
            background-image:url('../img/dart_hero_1.jpg');
            .overlay{
                opacity: 0.4;
            }
        }
    }

    .infoTwo{
        .background{
            background-color:@red;
            .overlay{
                display: none;
            }
        }
        .content{
            .innerContent{
                .text{
                    p{
                        @media @768{
                            padding-left: 20%;
                            padding-right: 20%;
                            font-size: 22px;
                            line-height: 28px;
                        }

                        @media @1024{
                            font-size: 22px;
                            line-height: 28px;
                        }
                    }
                }
            }
        }
    }

    .media{
        .mediaContainer{
            .imageWrapper{
                display: none;
            }   
        }
    }

    .press{
        .pressContainer{
            .pressItem{
                .title{
                    h2{
                        color: @red;
                    }
                }
            }   
        }
    }

    .stats{
        .statsContainer{
            .statsItem{
                .headline{
                    span{
                        color: @red;
                    }
                }
            }
        }
    }
}