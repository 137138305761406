
@import "mixins.less";
@import "fonts.less";

@import "pages/home.less";
@import "pages/showcase.less";
@import "pages/features.less";
@import "pages/cost.less";
@import "pages/contact.less";
@import "pages/howitworks.less";
@import "pages/aboutus.less";
@import "pages/faq.less";
@import "pages/services.less";
@import "pages/blog.less";
@import "pages/news.less";
@import "pages/goldstandard.less";
@import "pages/casestudies/casestudy.less";
@import "pages/casestudies/legendofyou.less";
@import "pages/casestudies/lollipop.less";
@import "pages/casestudies/letgo.less";
@import "pages/casestudies/seances.less";
@import "pages/casestudies/fordescapetheroom.less";
@import "pages/casestudies/hiddenwindow.less";
@import "pages/casestudies/petrjohn.less";
@import "pages/casestudies/juicyfruit.less";
@import "pages/casestudies/laheyvotes.less";
@import "pages/casestudies/tmobile.less";
@import "pages/casestudies/linkinpark.less";
@import "pages/casestudies/tostitos.less";
@import "pages/casestudies/tripadvisor.less";
@import "pages/casestudies/vzxmas.less";
@import "pages/casestudies/ihg.less";
@import "pages/casestudies/dart.less";
@import "pages/casestudies/orsted.less";
@import "pages/casestudies/vzpride.less";
@import "pages/casestudies/girlscount.less";
@import "pages/casestudies/remaxtools.less";
@import "pages/casestudies/marriott.less";
@import "pages/casestudies/havenlife.less";
@import "pages/casestudies/shawbillexplainer.less";
@import "pages/casestudies/vzthanks.less";

@import "partials/header.less";
@import "partials/footer.less";
@import "partials/mobileMenu.less";
@import "partials/videooverlay.less";
@import "partials/press.less";
@import "partials/credits.less";
@import "partials/stats.less";
@import "partials/media.less";

@font-face {
  font-family: 'DINNextLTPro-Light';
  src: url('../fonts/DINNextLTPro-Light.eot?#iefix') format('embedded-opentype'),  url('../fonts/DINNextLTPro-Light.otf')  format('opentype'),
         url('../fonts/DINNextLTPro-Light.woff') format('woff'), url('../fonts/DINNextLTPro-Light.ttf')  format('truetype'), url('../fonts/DINNextLTPro-Light.svg#DINNextLTPro-Light') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'DINCondensed-Bold';
  src: url('../fonts/DINCondensed-Bold.eot?#iefix') format('embedded-opentype'),  url('../fonts/DINCondensed-Bold.woff') format('woff'), url('../fonts/DINCondensed-Bold.ttf')  format('truetype'), url('DINCondensed-Bold.svg#DINCondensed-Bold') format('svg');
  font-weight: normal;
  font-smooth: always;
  font-style: normal;
}

.dinLight{
    font-family: "DINNextLTPro-Light";
}

html,
body{
     //Fixes iPhone scolling
    position:relative;
    height: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-overflow-scrolling: touch;
    overflow-x:hidden;
}

.item{
    
    position:relative;
    box-sizing:border-box;
    //border:1px solid black;
    float:left;
    vertical-align: top;

    .background{
        .layer;
    }
    .content{
        .layer;
        width: 100%;
    }
}

.grid-item{
    .item;
    height:300px;
    background-color: black;
}

.quad-item{
  display: inline-block;
  width:100%;
  height:50%;
}

.hero{
    .item;
    height:400px;
}


.container{
    width:100%;
    height: 100%;
    overflow: scroll;
    background-color: black;
    overflow-x:hidden;
}

/*  GRID OF TWELVE  */
.width-12{
    width: 100%;
}

.width-11{
    width: 91.66%;
}
.width-10 {
    width: 83.33%;
}

.width-9 {
    width: 75%;
}

.width-8 {
    width: 66.66%;
}

.width-7 {
    width: 58.33%;
}

.width-6 {
    width: 50%;
}

.width-5 {
    width: 41.66%;
}

.width-4 {
    width: 33.32%;
}

.width-3 {
    width: 25%;
}

.width-2 {
    width: 16.66%;
}

.width-1 {
    width: 8.333%;
}


/*  GO FULL WIDTH BELOW 480 PIXELS */
@media only screen and (max-width: 768px) {
    .width-1, .width-2, .width-3, .width-4, .width-5, .width-6, .width-7, .width-8, .width-9, .width-10, .width-11, .width-12 {
    width: 100%; 
    }
}

// For Google Chrome
::-webkit-scrollbar {
  width:   5px;
  height: .5em;
}

::-webkit-scrollbar-thumb {
  background: #605d5d;
  border-radius: 2px;
}

::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.0);
}

// For Internet Explorer
body {
    scrollbar-face-color: #605d5d;
    // scrollbar-track-color: #acacac;
}
