@keyframes ruleGrow {
    0% {
    	width:0%;
		opacity: 0;
    }
    100% {
    	width:100%;
    	opacity: 1;
    }
}

@keyframes fadeIn {
    0%   {
    		opacity: 0;
    	}
    100%  {
    	opacity: 0.6;
    }
}

.contact{
	-webkit-font-smoothing: antialiased;
	color: white;
	font-family: DINCondensed-Bold;
	text-transform: uppercase;
	.background{
		background-color: @darkerGrey;
		width: 100%;
		height: 100%;
		
		@media @768{
			//position: relative;
		}
		.overlay{
			.layer;
			.at2x('../img/imposium_bg.png', 1108px, 1108px);
			height: 100%;
			width: 100%;
			.cover;
			opacity: 0.6;
			left: 10%;
			bottom: 0;

			@media @768{
				left: 40%;
			}
		}
	}
	.contactContainer{
		.layer;
		display: table;

		.innerContact{
			display: table-cell;
			vertical-align: middle;
			
			.contactHeader{
				margin-top: 150px;

				@media @768{
					//margin-top: 250px;
				}
				
				.title{
					font-size: 120px;
	    			letter-spacing: 2px;
	    			line-height: 100px;
	   				margin-left: 15px;
	   				width: 205px;
	   				opacity: 0.6;
	   				animation: fadeIn 2s ease 1;

	   				@media @768{
	   					width: 460px;
	   					margin-left:50px;
	   				}

	   				@media @1366{
	   					width: 700px;
	   					margin-left:100px;
	   					font-size: 190px;
	   					line-height: 150px;
	   				}
				}

				.ruleHolder{
					width: 230px;
					opacity: 1;
					
					@media @768{
						width:470px;
					}

					@media @1366{
						width: 760px;
						margin-bottom: 20px;
					}

					.rule{
						border-bottom: 1px solid white;
						@media @768{
							opacity: 0;
						}

						&.grow{
							animation: ruleGrow 1s @easeInQuad forwards;
							opacity: 1;
						}						
					}
				}
			}
				
				h1{
					font-size: 50px;
					letter-spacing: 2px;
					line-height: 50px;
					width:125px;
				}

				p{
					font-size: 18px;
	   				letter-spacing: 2px;
	    			line-height: 20px;
	    			width: 150px;

	    			a{
	    				text-decoration: none;
	    				color: white;
	    				cursor: pointer;
	    				color: @yellow;
	    			}
				}

				h2{
					font-size: 18px;
	   				letter-spacing: 2px;
	    			line-height: 20px;
	    			color: @yellow;
	    			cursor: pointer;

	    			a{
	    				text-decoration: none;
	    				cursor: pointer;
	    				color: @yellow;
	    			}
				}

			.direct{
				height: 150px;
				background-color: transparent !important;
				.content{
					margin-left: 20px;
	    			display: table;
					.innerContent{
						   margin: 0 auto;
						   display: table-cell;
						   vertical-align: middle;

						   @media @768{
						   	    width: 200px;
	    						position: absolute;
							    top: 0;
							    bottom: 0;
							    right: 0;
							    left: 0;
							    height: 110px;
							    margin: auto;
							    display: inline-block;
						   	}

						h1{
			
						}

						p{

						}

						h2{
							a{
			    				text-decoration: none;
			    				cursor: pointer;
			    				color: @yellow;
	    					}

						}
					}
				}

			}

			.social{
				background-color: transparent !important;
				height: 150px;
				.content{
					margin-left: 20px;
					display: table;
					.innerContent{
						display: table-cell;
						vertical-align: middle;

						  @media @768{
						   	    width: 210px;
	    						position: absolute;
							    top: 0;
							    bottom: 0;
							    right: 0;
							    left: 0;
							    height: 110px;
							    margin: auto;
							    display: inline-block;
						   	}
							
						a{
							text-decoration: none;
							color: black;
							display: inline-block;
						}

						.icon{
							cursor: pointer;
							font-size: 30px;
							color: black;
							position: relative;
							margin-right: 10px;
							line-height: 0;
							background-color: white;
							//border: 2px solid white;
							border-radius: 100px;
							text-decoration: none;
							transition:background-color 300ms @easeInQuad;

							&:hover{
								background-color: @yellow;
								//border: 2px solid @yellow;
								//color: black;
							}
						}

						.youtube{
							display: inline-block;
							padding: 10px 12px;
							cursor: pointer;
							font-size: 30px;
							color: black;
							position: relative;
							margin-right: 10px;
							line-height: 0;
							background-color: white;
							//border: 2px solid white;
							border-radius: 100px;
							transition:background-color 300ms @easeInQuad;

							&:hover{
								background-color: #c4302b;
								//border: 2px solid @yellow;
								//color: black;
							}
						}

						.twitter{
							display: inline-block;
							padding: 10px 12px;
							cursor: pointer;
							font-size: 30px;
							color: black;
							position: relative;
							margin-right: 10px;
							line-height: 0;
							background-color: white;
							//border: 2px solid white;
							border-radius: 100px;
							transition:background-color 300ms @easeInQuad;

							&:hover{
								background-color: #33CCFF;
								//border: 2px solid @yellow;
								//color: black;
							}
						}

						.facebook{
							display: inline-block;
							padding: 10px 17px;
							cursor: pointer;
							font-size: 30px;
							color: black;
							position: relative;
							margin-right: 10px;
							line-height: 0;
							background-color: white;
							//border: 2px solid white;
							border-radius: 100px;
							transition:background-color 300ms @easeInQuad;

							&:hover{
								background-color: #3B5998;
								//border: 2px solid @yellow;
								//color: black;
							}

						}
					}
				}

			}

			.jobs{
				height: 150px;
				background-color: transparent !important;
				.content{
					margin-left: 20px;
					display: table;
					.innerContent{
						display: table-cell;
						vertical-align: middle;

						  @media @768{
						   	    width: 200px;
	    						position: absolute;
							    top: 0;
							    bottom: 0;
							    right: 0;
							    left: 0;
							    height: 110px;
							    margin: auto;
							    display: inline-block;
						   	}

					}
				}
			}
		}
	}   
}