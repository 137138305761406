.vzpride{

    .top{
        .background{
            background-image:url('../img/pride_hero_1.jpg');
            .overlay{
                opacity: 0.2;
            }
        }
    }

    .infoTwo{
        .background{
            background-image:url('../img/pride_hero_2.jpg');
            .overlay{
                opacity: 0.3;
            }
        }
    }

    .media{
        .mediaContainer{
            .imageWrapper{
                display: none;
            }   
        }
    }

    .stats{
        .statsContainer{
            .statsItem{
                .headline{
                    span{
                        color: @orstedBlue;
                    }
                }
            }
        }
    }
}