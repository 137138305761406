.linkinPark{

	.top{
		.background{
			background-image:url('../img/linkinpark_casestudy_hero.jpg');
		}
	}

	.infoTwo{
		.background{
			background-image:url('../img/linkinpark_casestudy_bg.jpg');
		}
	}
	
	.infoThree{
		.background{
			background-image:url('../img/lp_cs_bg.jpg');
		}
	}

	.media{
		.mediaContainer{
			.imageWrapper{
				display: none;
			}	
		}
	}
}
