.press{
	
	.item;

	background-color: @darkerGrey;
	
	color:white;

	.pressContainer{
		padding-bottom: 20px;
		padding-top: 20px;
		margin: 0 auto;

		@media @768{
			padding-bottom: 50px;
    		padding-top: 50px;
    		width: 460px;
		}

		@media @1024{
			width: 940px;
		}

		@media @1366{
			width: 1200px;
		}

		.pressItem{
			
			text-align: left;
			opacity: 1;
			margin-top:20px;
			
			padding:20px;
			box-sizing: border-box;
			vertical-align: text-top;

			width:100%;
			opacity: 1;


			@media @768{	
				max-width: 465px;
				display: inline-block;
			}

			@media @1366{
				max-width: 600px;
				padding:40px;
			}

			&:hover{
				@media @768{
					.rule{
						width: 85%;
						opacity: 1;
					}
				}
			}

			a{
				text-decoration: none;
			}

			.date{
				display: inline-block;
				.dinLight;
				font-size: 17px;
				color: white;
				text-transform: uppercase;
			}

			.title{
			
				width:100%;
				
				h2{
					display: inline-block;
					color:white;
					text-transform: uppercase;
					font-size: 23px;
					line-height: 25px;
					margin-right:10px;
					letter-spacing: 2px;
					color: @pressYellow;
				}
			}
			
			.headline{

				color:white;
				text-decoration: none;
				text-transform:uppercase;
				cursor: pointer;
				font-size: 17px;
				letter-spacing: 1px;
				.dinLight;
				line-height: 17px;
				margin-bottom: 10px;
			}

			.rule{
				transition:all 700ms @easeOutQuad;
				border-bottom: 1px solid white;
				margin-bottom: 10px;
				width: 0%;
				opacity: 0;
			}
		}	
	}
}