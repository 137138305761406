.cost{
	font-family: DINCondensed-Bold;
	-webkit-font-smoothing: antialiased;
	.text{
		.background{
			background-color: @green;
		}

		.content{
			display: table;
			.innerContent{
				display: table-cell;
				vertical-align: middle;
				h1{
					font-family: DINCondensed-Bold;
					text-transform: uppercase;
				    font-size: 85px;
	    			line-height: 68px;
				    text-align: left;
				    letter-spacing: 3px;
				    margin-left: 35px;
	    			width: 228px;
	    			color: white;

	    			@media @768{
	    				//line-height: 30px;
    					//width: 197px;
    					//font-size: 35px;
	    			}

				    @media @1024{
				    	//font-size: 49px;
	    				//line-height: 44px;
	    				//width: 260px;

				    }

				}


				.rule{
					border-bottom: 1px solid white;
					margin-top: 5px;
					margin-bottom: 10px;
					width: 203px;

					@media @768{
						//width: 223px;
					}

					@media @1024{
						//width: 288px;
					}
				}

				.ctaContainer{
					margin-left: 15px;
					.cta{
						color:white;
						font-size: 17px;
			    		letter-spacing: 2px;
			    		font-family: DINCondensed-Bold;
			    		text-transform: uppercase;
			    		margin-left: 15px;
			    		display: inline-block;

					}
				}
			}
		}
	}

	.top{
		.background{
			//.at2x('../img/howitworks_hero_destiny.jpg', 1920px, 1080px);
			//.ccnr;
			background-color: @darkerGrey;
		
			.overlay{
				//.layer;
				//background-color: rgba(0,0,0,0.6);
				.at2x('../img/imposium_bg.png', 1108px, 1108px);
				height: 100%;
				width: 100%;
				.cover;
				opacity: 0.7;

				@media @768{
    				width: 590px;
    				background-position: center;
    				background-repeat: no-repeat;
    				position: absolute;
    				right: -90px;
				}

				@media @1366{
					width: 860px;
					right: -120px;
				}
			}
		}
		.content{
			display: table;
			.innerContent{
				display: table-cell;
				vertical-align: middle;
					h1{
					font-family: DINCondensed-Bold;
					text-transform: uppercase;
					margin: 0 auto;
					font-size: 60px;
					margin-top: 10px;
	    			width: 320px;
	    			line-height: 48px;
	    			margin-left: 15px;
	    			letter-spacing: 2px;
	    			color: white;

	   				@media @768{
					   	font-size: 85px;
					    line-height: 63px;
					    margin-left: 50px;
    					width: 382px;
	    			}

	    			@media @960{
	    				font-size: 120px;
	    				width: 530px;
	    				margin-bottom: 10px;
	    			}

	    			@media @1366{
	    				margin-top: 20px;
	    			}
				}

				.rule{
					width: 274px;
					border-bottom: 1px solid white;

					@media @768{
						width: 408px;
					}

					@media @960{
						width: 548px;
					}
				}
				.description{
					.dinLight;
					color: white;
					text-align: left;
					margin-top: 10px;
					padding-left: 20px;
					padding-right: 20px;
					width: 260px;
	
					@media @768{
						width: 470px;
    					margin-left: 35px;
    					position: absolute;
					}
				}
			}
		}
	}

	.step{
		.background{
			background-color: @darkGrey;
		}

		.content{
			display: table;
			.innerContent{
				display: table-cell;
				vertical-align: middle;

				span{
					font-size: 65px;
				    color: #fff;
				    text-align: left;
				    position: relative;
				    line-height: 1;
				    margin-left: 15px;
				    @media @768{
				    	margin-left: 35px;
				    }

				    @media @1366{
				    	font-size: 100px;
				    }
				}
				
				h1{
					font-family: DINCondensed-Bold;
				    text-transform: uppercase;
				    font-size: 45px;
				    text-align: left;
				    letter-spacing: 3px;
				    margin-left: 15px;
				    width: 228px;
				    color: #fff;
				    line-height: 39px;
				    margin-top: 10px;


	    			@media @768{
		   				margin-left: 35px;
		   			}

		   			@media @1366{
		   				width: 278px;
		   				margin-top: 15px;
		   			}

				}


				.rule{
					border-bottom: 1px solid white;
					margin-top: 5px;
					margin-bottom: 10px;
					width: 203px;

					@media @768{
						//width: 223px;
					}

					@media @1024{
						//width: 288px;
					}
				}

				h2{
					color:white;
					font-size: 17px;
		    		letter-spacing: 2px;
		    		font-family: DINCondensed-Bold;
		    		text-transform: uppercase;
		    		margin-left: 15px;
		    		display: inline-block;

		    		@media @768{
		   				margin-left: 35px;
		   			}

				}

				p{
					.dinLight;
					margin-left: 15px;
					color: white;
		   			font-size: 14px;
		   			width: 270px;

		   			@media @768{
		   				margin-left: 35px;
		   				width: 203px;
		   			}

		   			@media @960{
		   				width: 270px;
					    font-size: 17px;
					    margin-top: 5px;
		   			}

		   			@media @1366{
		   				width:360px;
		   			}
				}
				
			}
		}
	}

	.dev{
		.background{
			background-color: @darkGrey;
		}

		.stepIcon{
			background-image: url("../img/code.svg");
			height: 70px;
		    width: 100%;
		    background-repeat: no-repeat;
		    margin-left: 15px;
			
			@media @768{
				margin-left: 35px;
				height: 100px;
			}
		}
	}

	.render{
		.background{
			background-color: @darkerGrey;
		}

		.stepIcon{
			background-image: url("../img/pay.svg");
			height: 70px;
		    width: 100%;
		    background-repeat: no-repeat;
		    margin-left: 15px;

		    @media @768{
				margin-left: 35px;
				height: 100px;
			}
		}
	}

	.budget{
		.background{
			background-color: @darkGrey;
		}

		.stepIcon{
			background-image: url("../img/shield.svg");
			height: 70px;
		    width: 100%;
		    background-repeat: no-repeat;
		    margin-left: 15px;

		   	@media @768{
				margin-left: 35px;
				height: 100px;
			}
		}
	}

	.bottom{
		height: 500px;

		@media @768{
			height: 570px;
		}
		.background{
			background-color: @green;
		}

		.content{
			display: table;
			.innerContent{
				display: table-cell;
				vertical-align: middle;
				color: white;

				.rule{
					width: 274px;
					border-bottom: 1px solid white;

					@media @768{
						width: 408px;
					}

					@media @960{
						width: 448px;
					}
				}
				.left{
					margin-top: 20px;
					width: 100%;
					@media @768{
						margin-left: 35px;
    					margin-top: 25px;
					}
					@media @960{
						margin-top:45px;
					}
					.inputItem{
						width: 48%;
						display: inline-block;
						text-align: center;
						span{
							font-size: 55px;
						    color: #fff;
						    text-align: left;
						    position: relative;
						    line-height: 1;

						    @media @1366{
								font-size: 90px;
						    }
						}
						.title{
							color: #fff;
						    font-size: 30px;
						    text-align: center;
						    text-transform: uppercase;
						    margin-bottom: 0;
						    height: 40px;
						    letter-spacing: 2px;
						}
						.box{
							position: relative;
							border: 2px solid white;
							margin: auto;
							width: 100px;
							height: 40px;
							text-align: center;
							input{
								width: 100%;
								height: 100%;
								text-align: center;
								color:white;
								border: none;
								line-height: 46px;
 								background-color: transparent;
								font-size: 25px;
								letter-spacing: 2px;
								font-family: DINCondensed-Bold;
								padding: 0px;
							}
							@media @768{
								font-size: 30px;
								line-height: 56px;
								width: 120px;
								height: 50px;
							}
						}
						.caption{
							margin-top: 2px;
							text-transform: uppercase;
							text-align: center;
							letter-spacing: 2px;
							@media @768{
								margin-top: 5px;
								font-size: 20px;
							}
						}
					}
					@media @768{
						width: 40%;
						float: left;
					}
				}

				.length{
					.inputIcon{
						background-image: url("../img/time.svg");
						.ccnr;
						height: 80px;
			    		width: 80px;
			    		margin: 0 auto;
			    		margin-bottom: 5px;

			    		@media @768{
							height: 100px;
			    			width: 100px;
			    		}
					}
				}
				
				.users{
					.inputIcon{
						background-image: url("../img/user.svg");
						.ccnr;
						height: 80px;
			    		width: 80px;
			    		margin: 0 auto;

			    		@media @768{
			    			height: 100px;
			    			width: 100px;
			    		}
					}
				}

				.right{
					margin-top: 40px;
					width: 100%;
					@media @768{
						margin-right: 35px;
					}
					.lineItem{
						width: 290px;
						height: 50px;
						margin: auto;
						margin-bottom: 10px;
						font-size: 20px;

						@media @960{
							width: 350px;
						}
						@media @960{
							height: 70px;
						}
						.title{
							text-transform: uppercase;
							display: inline-block;
							letter-spacing: 2px;
    						font-size: 15px;

    						@media @768{
    							font-size: 18px;
    						}

    						@media @960{
    							font-size: 22px;
    						}
						}
						.amount{
							display: inline-block;
							text-align: right;
						}
						.rule{
							display: block;
							width: 100%;
						}
						.caption{
							display: block;
							font-size: 14px;
							.dinLight;

							@media @960{
								font-size: 16px;
								margin-top: 5px;
							}
						}
						&.render{
							.amount{
								width:227px;
								@media @960{
									width:280px;
									font-size: 25px;
								}
							}
						}
						&.storage{
							.amount{
								width:220px;
								@media @960{
									width:270px;
									font-size: 25px;
								}
							}
						}
						&.total{
							.amount{
								width:240px;
								@media @960{
									width:294px;
									font-size: 25px;
								}
							}
						}
					}
					@media @768{
						margin-left:auto;
						width: 40%;
						float: right;
					}
				}
			}	
		}
	} 
}