
.services{
	font-family: DINCondensed-Bold;
	-webkit-font-smoothing: antialiased;
	.top{
		.background{
			.at2x('../img/services_hero.jpg', 1920px, 900px);
			.cover;
			.ccnr;
			
			.overlay{
				.layer;
				background-color: rgba(0,0,0,0.6);
			}
		}

		.content{
			display: table;
			.innerContent{
				display: table-cell;
				vertical-align: middle;
				
				h1{
					opacity: 0.6;	
					font-size: 50px;
					color: white;
					line-height: 42px;
					text-transform: uppercase;
					font-family: DINCondensed-Bold;
					width: 300px;
					margin-left: 15px;
					margin-top: 40px;

					@media @768{
						font-size: 69px;
					    line-height: 53px;
					    margin-top: 70px;
					    width: 400px;
					    margin-left: 50px;
					}

					@media @960{
						font-size: 90px;
   						line-height: 68px;
					    margin-top: 70px;
					    width: 520px;
					}

					@media @1366{
						font-size: 125px;
    					line-height: 97px;
					    width: 683px;
					}
				}

				.rule{
					width: 265px;
					border-bottom: 1px solid white;
    				margin-top: 0px;
					margin-bottom: 5px;

					@media @768{
						width: 340px;
					}

					@media @768{
						width: 430px;
					}

					@media @1366{
						width: 650px;
					}
				}

				p{
					width: 250px;
	   				.dinLight;
	   				font-size: 14px;
	   				color: white;
	   				margin-left: 15px;
	   				margin-top: 10px;

	   				@media @768{
	   					width: 300px;
	   					margin-left:50px;
	   				}

	   				@media @960{
	   					font-size: 17px;
	   					width: 450px;
	   				}

	   				@media @1366{
	   					width: 630px;
	   				}

				}
			}
		}
	}

	.featured{
		height: 500px;
		color: white;
		.content{
			display: table;
			.innerContent{
				display: table-cell;
				vertical-align: middle;
				
				.text{
					margin-left: 15px;

					@media @768{
						margin-left:50px;
						margin-top: 20px;
					}

					h1{
						font-family: DINCondensed-Bold;
						text-transform: uppercase;
					    font-size: 25px;
		    			line-height: 37px;
					    text-align: left;
					    letter-spacing: 3px;
						line-height: 28px;
		    			width: 80%;
   						margin-top: 5px;

   						@media @960{
   							font-size: 45px;
   							line-height: 38px;
   						}
					}

					p{
						width: 85%;
		   				.dinLight;
		   				font-size: 14px;

		   				@media @500{
		   					//width: 395px;
		   					width: 75%;
		   				}

		   				@media @960{
		   					font-size: 17px;
		   					//width:505px;
		   				}

		   				@media @1366{
		   					//width: 600px;
		   				}

					}

					.ctaBtn{
						cursor: pointer;
						width: 114px;
	    				height: 33px;
	   					border: 2px solid #fff;
	    				margin-top: 10px;
	    				position: relative;
	    				transition:color 300ms @easeInQuad;

	    				.bg{
	    					bottom: 0;
	    					width: 0;
	    					height: 100%;
	    					background-color: white;
	    					z-index: 1;
	    					position: absolute;
	    					transition:width 300ms @easeInQuad;
	    				}

						.cta{
							text-transform: uppercase;
							font-size: 17px;
							margin-left: 15px;
							margin-top: 10px;
							letter-spacing: 2px;
							display: inline-block;
							text-indent: 2px;
	    					line-height: 20px;
	    					position: absolute;
	    					z-index: 2;
						}

						.arrow{
							color: white;
		    				font-size: 15px;
		    				display: inline-block;
		    				position: absolute;
		    				margin-left: 89px;
	    					line-height: 39px;
		    				z-index: 3;
		    				transition:color 300ms @easeInQuad;

						}

						&:hover{
							color: @purple;

							.arrow{
								color: @purple;
							}

							.bg{
								width: 100%;
							}
						}
					}

				}

				span{
					font-size: 50px;
   					margin-left: 15px;
   					margin-bottom: 15px;
   					line-height: normal;
   					opacity: 0.8;

   					@media @768{
   						font-size: 190px;
    					position: relative;
    					float: right;
    					margin-left: 0px;
    					margin-bottom: 0px;
    					margin-right: 100px;
   					}

				}
			}
		}
	}

	.gridFeature{
		.content{
			.innerContent{			
				.featuredIcon{
					position: relative;
					.ccnr;
					height: 70px;
		    		width: 70px;
		    		bottom: 10px;
		    		margin-left: 15px;
		    		
		    		@media @768{
						height: 120px;
					    width: 120px;
					    margin-left: 0;
					    margin-bottom: 0;
					    position: absolute;
					    right: 20px;
					    top: 0;
					    bottom: 0;
					    height: 100%;
		    		}

		    		@media @1200{
						right: 80px;
		    		}
				}
			}
		}
	}

	.heroFeature{
		.content{
			.innerContent{			
				.featuredIcon{
					//font-size: 45px;
					//color:white;
					//text-align: center;
					position: relative;
					.ccnr;
					height: 100px;
		    		width: 100px;
		    		bottom: 10px;
		    		margin-left: 15px;
		    		
		    		@media @768{
					    height: 150px;
					    width: 150px;
					    margin-left: 0;
					    margin-right: 100px;
					    margin-bottom: 0;
					    margin: 0 auto;
					    position: absolute;
					    top: 0;
					    bottom: 0;
					    right: 20px;
					    height: 100%;
		    		}

		    		@media @960{
		    			right: 50px;
		    		}

		    		@media @1024{
		    			right: 80px;
		    		}

		    		@media @1366{
		    			right: 100px;
		    		}
				}
			}
		}
	}



	.performance{	
		.featuredIcon{
			background-image: url("../img/performance.svg");
		}
	}

	.integration{
		.featuredIcon{
			background-image: url("../img/user.svg");
		}
	}

	.web{
		.featuredIcon{
			background-image: url("../img/globe.svg");
		}
	}


	.audience{	
		.featuredIcon{
			background-image: url("../img/audience.svg");
		}
	}

	.units{	
		.featuredIcon{
			background-image: url("../img/units.svg");
		}
	}

	.dev{			
		.featuredIcon{
			background-image: url("../img/dev.svg");
		}
	}

	.text{			
		.featuredIcon{
			background-image: url("../img/text_entry.svg");
		}
	}


	.socialVideo{
		.featuredIcon{
			background-image: url("../img/social_video.svg");
		}
	}

	.engagment{		
		.featuredIcon{
			background-image: url("../img/engagment.svg");
		}
	}

	.social{		
		.featuredIcon{
			background-image: url("../img/social.svg");
		}
	}

	.even{
		.background{
			// .at2x('../img/social_hero_feature.jpg', 1920px, 973px);
			.cover;
			.ccnr;
			.overlay{
				.layer;
				background-color: @darkerGrey;
				opacity: 0.8;
			}
		}
	}

	.odd{
		.background{
			// .at2x('../img/rotoscoping_hero_feature.jpg', 1920px, 973px);
			.cover;
			.ccnr;
			.overlay{
				.layer;
				background-color: @darkGrey;
				opacity: 0.8;
			}
		}
	}


	.featuredGrid{
		color: white;
		.background{
			//background-color: @darkGrey;
		}
		.content{
			display: table;
			.innerContent{
				display: table-cell;
				vertical-align: middle;
				.text{
					margin-left: 15px;

					@media @768{
						margin-left: 25px;
					}

					@media @960{
						margin-left: 50px;
					}
					
					h1{
						font-family: DINCondensed-Bold;
						text-transform: uppercase;
					    font-size: 37px;
		    			line-height: 37px;
					    text-align: left;
					    letter-spacing: 3px;
						line-height: 38px;
		    			width: 260px;

		    			@media @768{
		    				width: 300px;				
		    			}

					}

					p{
						width: 260px;
	   					.dinLight;
	   					font-size: 14px;

	   					@media @960{
	   						font-size: 17px;
	   						width: 315px;
	   					}

	   					@media @1366{
	   						width:377px;
	   					}

					}

					.ctaBtn{
						cursor: pointer;
						width: 114px;
	    				height: 33px;
	   					border: 2px solid #fff;
	    				margin-top: 10px;
	    				position: relative;
	    				transition:color 300ms @easeOutQuad;

	    				.bg{
	    					bottom: 0;
	    					width: 0;
	    					height: 100%;
	    					background-color: white;
	    					z-index: 1;
	    					position: absolute;
	    					transition:width 300ms @easeOutQuad;
	    				}

						.cta{
							text-transform: uppercase;
							font-size: 17px;
							margin-left: 15px;
							margin-top: 10px;
							letter-spacing: 2px;
							display: inline-block;
							text-indent: 2px;
	    					line-height: 20px;
	    					position: absolute;
	    					z-index: 2;
	    					

						}

						.arrow{
							color: white;
		    				font-size: 15px;
		    				display: inline-block;
		    				position: absolute;
		    				margin-left: 89px;
	    					line-height: 39px;
		    				z-index: 3;
		    				transition:color 300ms @easeOutQuad;

						}

						&:hover{
							color: @darkerGrey;

							.arrow{
								color: @darkerGrey;
							}

							.bg{
								width: 100%;
							}
						}
					}
				}

				span{
					font-size: 50px;
   					margin-left: 15px;
   					margin-bottom: 15px;
   					line-height: normal;

   					@media @768{
   						font-size: 80px;
    					position: relative;
    					right: 0;
    					top: 0;
    					margin-right: 25px;
    					float: right;
    					margin-left: 0px;
    					margin-bottom: 0px;
   					}

   					@media @960{
   						font-size: 100px;
   					}

   					@media @1024{
   						font-size: 130px;
    					margin-right: 50px;
    					margin-left: 10px;
   					}

   					@media @1366{
   						font-size: 170px;
   					}
				}
			}
		}		
	}

	.even{
		.background{
			background-color: @middleGrey;
		}
	}

	
	.middle{
		.background{
			background-color:#1c1c1c;
		}
	}

	.odd{
		.background{
			background-color: @darkerGrey;
		}
	}

	.bottom{
		height: 800px;

		@media @768{
			height: 500px;
		}
		.background{
			.at2x('../img/tmobile_bg.jpg', 1920px, 1080px);
			.cover;
			.ccnr;
			
			.overlay{
				.layer;
				background-color: rgba(0,0,0,0.6);
			}
		}
		.content{
			display: table;	
			.innerContent{
				display: table-cell;
				vertical-align: middle;
				.left{

					h1{
						font-family: DINCondensed-Bold;
						text-transform: uppercase;
						margin: 0 auto;
						font-size: 60px;
						margin-top: 80px;
		    			width: 310px;
		    			line-height: 48px;
		    			margin-left: 15px;
		    			letter-spacing: 2px;
		    			color: white;

		   				@media @768{
					   		font-size: 45px;
						    line-height: 38px;
						    margin-left: 50px;
						    margin-top: 30px;
						    width: 300px;
					    }

		    			@media @960{
		    				font-size: 79px;
						    width: 520px;
						    line-height: 60px;
		    			}

		    			@media @1366{
		    				font-size: 90px;
    						width: 544px;
    						line-height: 70px;
		    			}
					}

					.rule{
						width: 295px;
						border-bottom: 1px solid white;

						@media @768{
							width: 312px;
						}

						@media @960{
							width: 488px;
						}

						@media @1366{
							width: 548px;
						}
					}

					.ctaContainer{
						text-transform: uppercase;
						font-size: 17px;
						margin-left: 15px;
						letter-spacing: 2px;
						color: white;
						cursor: pointer;
						margin-top: 5px;
						
						.cta{
							display: inline-block;
							a{
								text-decoration: none;
								color: white;
							}
						}

						.arrow{
	    					font-size: 15px;
	    					display: inline-block;
	    					margin-left: 10px;

						}

						@media @768{
							margin-left: 50px;
						}
					}
				}

				.right{
					width: 310px;
					margin: 0 auto;
					margin-top: 30px;

					@media @768{
						//float: right
						margin: 0 auto;
						width:  590px;
					}

					@media @1024{
						width: 600px;
					}

					@media @1366{
						width: 750px;
					}

					.benefits{
						.benItems{
							margin-bottom: 20px;
							margin-top: 20px;

							@media @1366{
								margin-right: 40px;
							}
							
							h1{
								font-size: 19px;
							    margin-top: 10px;
							    width: 130px;
							    line-height: 17px;
							    margin-bottom: 5px;
							    color: white;
							    font-family: DINCondensed-Bold;
								text-transform: uppercase;
								margin-left: 15px;
								letter-spacing: 2px;
							}

							p{
								.dinLight;
								color: white;
			   					font-size: 14px;
		    					margin-left: 15px;
		    					width: 130px;
							}

							span{
								font-size: 45px;
								color:white;
								text-align: left;
								margin-left: 20px;
							}
						}

						.benIcon{
							margin-left: 20px;
							margin-bottom: 5px;
						}
						
						.dev{
							display: inline-block;

							.devIcon{
								position: relative;
								background-image: url("../img/fast.svg");
								.ccnr;
								height: 55px;
					    		width: 55px;
							}
							
						}

						.flex{
							display: inline-block;

							.flexIcon{
								position: relative;
								background-image: url("../img/flexibility.svg");
								.ccnr;
								height: 55px;
					    		width: 55px;
							}
						}

						.quality{
							display: inline-block;

							.qualityIcon{
								position: relative;
								background-image: url("../img/award.svg");
								.ccnr;
								height: 55px;
					    		width: 55px;
							}
						}

						.masses{
							display: inline-block;

							.massesIcon{
								position: relative;
								background-image: url("../img/masses.svg");
								.ccnr;
								height: 55px;
					    		width: 55px;
							}
						}
					}
				}
			}
	 	}
    }
}