@font-face {
    font-family: 'icomoon';
    src:    url('../fonts/icomoon.eot?n3tksp');
    src:    url('../fonts/icomoon.eot?n3tksp#iefix') format('embedded-opentype'),
        url('../fonts/icomoon.ttf?n3tksp') format('truetype'),
        url('../fonts/icomoon.woff?n3tksp') format('woff'),
        url('../fonts/icomoon.svg?n3tksp#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-city:before {
    content: "\e60b";
}
.icon-pencil5:before {
    content: "\e613";
}
.icon-vector:before {
    content: "\e61a";
}
.icon-brush2:before {
    content: "\e61e";
}
.icon-eye-dropper:before {
    content: "\e625";
}
.icon-gradient:before {
    content: "\e629";
}
.icon-magic-wand:before {
    content: "\e62b";
}
.icon-compass:before {
    content: "\e62f";
}
.icon-aim:before {
    content: "\e630";
}
.icon-drop:before {
    content: "\e633";
}
.icon-cloud-upload:before {
    content: "\e64b";
}
.icon-database:before {
    content: "\e65d";
}
.icon-database-check:before {
    content: "\e662";
}
.icon-database-history:before {
    content: "\e663";
}
.icon-shield-check:before {
    content: "\e668";
}
.icon-cog:before {
    content: "\e672";
}
.icon-wrench:before {
    content: "\e674";
}
.icon-hammer-wrench:before {
    content: "\e676";
}
.icon-eye:before {
    content: "\e6a5";
}
.icon-binoculars:before {
    content: "\e6a9";
}
.icon-hdd-down:before {
    content: "\e6ac";
}
.icon-compare:before {
    content: "\e6b9";
}
.icon-scissors:before {
    content: "\e6c7";
}
.icon-document:before {
    content: "\e6d8";
}
.icon-trophy2:before {
    content: "\e6e3";
}
.icon-shuffle:before {
    content: "\e6ec";
}
.icon-equalizer:before {
    content: "\e6f2";
}
.icon-play:before {
    content: "\e6f7";
}
.icon-film:before {
    content: "\e6fb";
}
.icon-camera:before {
    content: "\e6ff";
}
.icon-time-lapse:before {
    content: "\e702";
}
.icon-face-detection:before {
    content: "\e70a";
}
.icon-picture2:before {
    content: "\e70f";
}
.icon-portrait:before {
    content: "\e71c";
}
.icon-user-plus:before {
    content: "\e71f";
}
.icon-users2:before {
    content: "\e723";
}
.icon-group-work:before {
    content: "\e726";
}
.icon-cash-dollar:before {
    content: "\e75a";
}
.icon-bag-dollar:before {
    content: "\e75e";
}
.icon-coin-dollar:before {
    content: "\e762";
}
.icon-calculator2:before {
    content: "\e767";
}
.icon-map-marker:before {
    content: "\e77a";
}
.icon-radar:before {
    content: "\e77f";
}
.icon-location:before {
    content: "\e783";
}
.icon-calendar-check:before {
    content: "\e786";
}
.icon-spell-check:before {
    content: "\e795";
}
.icon-screen:before {
    content: "\e798";
}
.icon-smartphone:before {
    content: "\e7a6";
}
.icon-launch:before {
    content: "\e7b0";
}
.icon-power:before {
    content: "\e7b9";
}
.icon-bubble-text:before {
    content: "\e7db";
}
.icon-pie-chart:before {
    content: "\e7f8";
}
.icon-chart-growth:before {
    content: "\e7fb";
}
.icon-rocket:before {
    content: "\e837";
}
.icon-dumbbell:before {
    content: "\e861";
}
.icon-cube:before {
    content: "\e874";
}
.icon-earth:before {
    content: "\e884";
}
.icon-network:before {
    content: "\e886";
}
.icon-happy:before {
    content: "\e889";
}
.icon-volume-high:before {
    content: "\e8b0";
}
.icon-mic:before {
    content: "\e8cb";
}
.icon-history2:before {
    content: "\e8e4";
}
.icon-alarm-check:before {
    content: "\e8ed";
}
.icon-timer2:before {
    content: "\e8f1";
}
.icon-bug:before {
    content: "\e90a";
}
.icon-code:before {
    content: "\e90b";
}
.icon-file-preview:before {
    content: "\e911";
}
.icon-link2:before {
    content: "\e917";
}
.icon-thumbs-up2:before {
    content: "\e91b";
}
.icon-thumbs-up3:before {
    content: "\e91d";
}
.icon-cross:before {
    content: "\e92a";
}
.icon-menu:before {
    content: "\e92b";
}
.icon-arrows-split:before {
    content: "\e947";
}
.icon-expand:before {
    content: "\e94a";
}
.icon-move:before {
    content: "\e94e";
}
.icon-tab:before {
    content: "\e94f";
}
.icon-frame-expand:before {
    content: "\e971";
}
.icon-transform:before {
    content: "\e974";
}
.icon-layers:before {
    content: "\e977";
}
.icon-exclude:before {
    content: "\e986";
}
.icon-text-format:before {
    content: "\e9a0";
}
.icon-text-wrap:before {
    content: "\e9af";
}
.icon-border-none:before {
    content: "\e9e8";
}

