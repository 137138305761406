.news{
	font-family: DINCondensed-Bold;
	-webkit-font-smoothing: antialiased;

	a{
		text-decoration: none;
		color: white;
	}
	.top{
		.background{
			background-color: @darkerGrey;
			.cover;
			.ccnr;

			.imposium{
				.at2x('../img/imposium_bg.png', 1108px, 1108px);
				height: 100%;
				width: 100%;
				.cover;
				opacity: 0.4;

				@media @768{
    				width: 590px;
    				background-position: center;
    				background-repeat: no-repeat;
    				position: absolute;
    				right: -90px;
				}

				@media @1366{
					width: 860px;
					right: -120px;
				}
			}
		}

		.content{
			display: table;
			.innerContent{
				display: table-cell;
				vertical-align: middle;
				
				h2{
					opacity: 0.6;	
					font-size: 50px;
					color: white;
					line-height: 42px;
					text-transform: uppercase;
					font-family: DINCondensed-Bold;
					width: 300px;
					margin-left: 15px;
					margin-top: 40px;

					@media @768{
						font-size: 69px;
					    line-height: 53px;
					    margin-top: 70px;
					    width: 400px;
					    margin-left: 35px;
					}

					@media @960{
						font-size: 90px;
   						line-height: 68px;
					    margin-top: 70px;
					    width: 520px;
					}

					@media @1366{
						font-size: 125px;
    					line-height: 97px;
					    width: 683px;
					}
				}

				.rule{
					width: 265px;
					border-bottom: 1px solid white;
    				margin-top: 0px;
					margin-bottom: 5px;

					@media @768{
						width: 250px;
					}

					@media @960{
						width: 390px;
					}

					@media @1366{
						width: 540px;
					}
				}
			}
		}
	}

	.article{
		cursor: pointer;
		overflow: hidden;

		.logo{
		    position: absolute;
		    top: 20px;
		    bottom: 0;
		    right: 20px;
		    margin: 0 auto;
		    opacity: 0.5;

		    @media @768{
		    	display: none;
		    }

		    @media @960{
		    	display: block;
		    }

		    @media @1200{
		    	top: 30px;
			    bottom: 0;
			    right: 30px;
		    }
		}
		
		.content{
			display: table;
			.innerContent{
				display: table-cell;
				vertical-align: middle;
				color: white;

				.title{
					text-transform: uppercase;
					font-family: DINCondensed-Bold;
					margin-bottom: 30px;
					letter-spacing: 2px;
					font-size: 14px;
				}

				h4{
					font-size: 19px;
	    			margin-top: 0;
	    	    	width: 70%;
    				line-height: 22px;
				    margin-bottom: 5px;
				    letter-spacing: 1px;
				    text-transform: uppercase;
				    margin-left: 35px;
				    margin-top: 15px;

				    @media @768{
				    	font-size: 15px;
					    width: 80%;
					    line-height: 16px;
				    }

				    @media @960{
				    	font-size: 28px;
				    	line-height: 26px;
				    }
				}

				.rule{
					border-bottom: 1px solid white;
					margin-top: 10px;
					margin-bottom: 10px;
					width: 70%;

				}

				p{
					width: 80%;
		   			margin-left: 35px;
		   			.dinLight;
		   			font-size: 14px;
		   			line-height: 17px;

		   			@media @1366{
		   				font-size: 16px;
		   				line-height: 19px;
		   				width: 70%;
		   			}
				}

				.ctaContainer{
					cursor: pointer;
					.cta{
						margin-left: 35px;
						text-transform: uppercase;
						letter-spacing: 2px;
						margin-top: 10px;
						display: inline-block;
						a{
							text-decoration: none;
							color: white;
						}
					}

					.arrow{
						display: inline-block;
						font-size: 13px;
			    		display: inline-block;
			    		margin-left: 5px;
					}

					&:hover{
						.arrow{
							.fa{
								animation: arrowHover 0.55s @easeInOutQuad 0.1s 1 forwards;
							}
						}
					}
				}
			}
		}
	}

	.article-small{	
		height: 300px;
		cursor: pointer;

		@media @768{
			height: 50%;
		}
		.logo{
		    right: 20px;
		    top: 20px;
		    opacity: 0.3;
		}
		.content{
			.innerContent{
				h4{
					font-size: 20px;
	    			margin-top: 0;
	    	    	width: 70%;
    				line-height: 22px;
				    margin-bottom: 5px;
				    letter-spacing: 1px;
				    text-transform: uppercase;
				    margin-left: 35px;
				    margin-top: 15px;

				   	@media @768{
				    	font-size: 15px;
					    width: 80%;
					    line-height: 16px;
				    }

				    @media @1200{
				    	font-size: 20px;
				    	line-height: 22px;
				    }
				}
			}
		}
	}


	.letGo{	
		.logo{
			height: 38px;
			width: 63px;
			.at2x('../img/logo_letgo.png', 63px, 38px);

			@media @1200{						
				height: 75px;
				width: 125px;
				.at2x('../img/logo_letgo.png', 125px, 75px);
			}
		}
	}

	.ford{
		.logo{
			.at2x('../img/logo_ford.png', 83px, 33px);
			width: 83px;
			height: 33px;

			@media @1200{						
				.at2x('../img/logo_ford.png', 165px, 66px);
				width: 165px;
				height: 66px;
			}
		}
	}


	.nfb{
		.logo{
			.at2x('../img/logo_nfb.png', 70px, 34px);
		    height: 34px;
		    width: 70px;

			@media @1200{
				.at2x('../img/logo_nfb.png', 140px, 68px);
			    height: 68px;
			    width: 140px;
			}
		}
	}


	.nickel{
		.logo{
			.at2x('../img/nickel_logo_small.png', 41px, 41px);
		    height: 41px;
		    width: 41px;

			@media @1200{
				.at2x('../img/nickel_logo_small.png', 83px, 83px);
			    height: 83px;
			    width: 83px;
			}
		}
	}


	.mashie{
		.logo{
			.at2x('../img/mashies_logo.png', 50px, 50px);
		    height: 50px;
		    width: 50px;

			@media @1200{
				.at2x('../img/mashies_logo.png', 100px, 100px);
			    height: 100px;
			    width: 100px;
			}
		}
	}


	.activision{
		.logo{
			.at2x('../img/activision_logo.png', 85px, 21px);
			width: 85px;
			height: 21px;

			@media @1200{
				.at2x('../img/activision_logo.png', 169px, 42px);
				width: 169px;
				height: 42px;
			}
		}
	}


	.even{
		.background{
			background-color: #3588BA;
		}
	}

	
	.mid{
		.background{
			background-color:#1c1c1c;
		}
	}

	.odd{
		.background{
			background-color: @darkerGrey;
		}
	}

	.hero-letgo{
		.at2x('../img/letgo_bg_news.jpg', 640px, 640px);
		.cover;
		.ccnr;

		.overlay{
			.layer;
			background-color: rgba(0,0,0,0.7);
		}
	}

	.hero-ford{
		.at2x('../img/ford_news.jpg', 640px, 640px);
		.cover;
		.ccnr;

		.overlay{
			.layer;
			background-color: rgba(0,0,0,0.7);
		}
	}

	.hero-fordTwo{
		.at2x('../img/ford_two_news.jpg', 640px, 640px);
		.cover;
		.ccnr;

		.overlay{
			.layer;
			background-color: rgba(0,0,0,0.7);
		}
	}

	.hero-seances{
		.at2x('../img/seances_news.jpg', 640px, 640px);
		.cover;
		.ccnr;

		.overlay{
			.layer;
			background-color: rgba(0,0,0,0.7);
		}
	}

	.hero-seancesTwo{
		.at2x('../img/seances_two_news.jpg', 640px, 640px);
		.cover;
		.ccnr;

		.overlay{
			.layer;
			background-color: rgba(0,0,0,0.6);
		}
	}
	
	.hero-tostitos{
		.at2x('../img/tostitos_news_bg.jpg', 640px, 640px);
		.cover;
		.ccnr;

		.overlay{
			.layer;
			background-color: rgba(0,0,0,0.6);
		}
	}

	.hero-havenlife{
		.at2x('../img/hl_news_bg.jpg', 640px, 640px);
		.cover;
		.ccnr;

		.overlay{
			.layer;
			background-color: rgba(0,0,0,0.6);
		}
	}

	.hero-mashie{
		.at2x('../img/mashieaward_news_bg.jpg', 640px, 640px);
		.cover;
		.ccnr;

		.overlay{
			.layer;
			background-color: rgba(0,0,0,0.6);
		}
	}

	.hero-lollipop{
		.at2x('../img/lollipop_news.jpg', 640px, 640px);
		.cover;
		.ccnr;

		.overlay{
			.layer;
			background-color: rgba(0,0,0,0.4);
		}
	}

	.hero-lollipopTwo{
		.at2x('../img/lollipopTwo_news_bg.jpg', 640px, 640px);
		.cover;
		.ccnr;

		.overlay{
			.layer;
			background-color: rgba(0,0,0,0.4);
		}
	}

	.hero-destiny{
		.at2x('../img/destiny_news.jpg', 640px, 640px);
		.cover;
		.ccnr;

		.overlay{
			.layer;
			background-color: rgba(0,0,0,0.7);
		}
	}

	.hero-remax{
		.at2x('../img/remax_news_bg.jpg', 584px, 482px);
		.cover;
		.ccnr;

		.overlay{
			.layer;
			background-color: rgba(0,0,0,0.5);
		}
	}

	.hero-ces{
		.at2x('../img/ces_news_bg.jpg', 584px, 482px);
		.cover;
		.ccnr;

		.overlay{
			.layer;
			background-color: rgba(0,0,0,0.5);
		}
	}

	.hero-destinyTwo{
		.at2x('../img/destiny_two_news.jpg', 640px, 640px);
		.cover;
		.ccnr;

		.overlay{
			.layer;
			background-color: rgba(0,0,0,0.7);
		}
	}

	.hero-vzthanks{
		.at2x('../img/vzthanks_news_bg.jpg', 584px, 482px);
		.cover;
		.ccnr;

		.overlay{
			.layer;
			background-color: rgba(0,0,0,0.7);
		}
	}

	.hero-marriott{
		.at2x('../img/marriott_news_bg.jpg', 584px, 482px);
		.cover;
		.ccnr;

		.overlay{
			.layer;
			background-color: rgba(0,0,0,0.7);
		}
	}

	.hero-girlscount{
		.at2x('../img/girlscount_news_bg.jpg', 584px, 482px);
		.cover;
		.ccnr;

		.overlay{
			.layer;
			background-color: rgba(0,0,0,0.7);
		}
	}
}