.tostitos{
    
    .top{
        .background{
            background-image:url('../img/tostitos_hero@2x.jpg');
        }
    }

    .infoTwo{
        .background{
            background-image:url('../img/tostitos_hero_2.jpg');
        }
    }
    
    .infoThree{
        .background{
            background-image:url('../img/tostitos_hero_3.jpg');
        }
    }

    .media{
        .mediaContainer{
            .imageWrapper{
                display: block;
                
                .thumbnail{
                    display: inline-block;
                    width:33.3%;
                    .bg{
                        width:33.3%;
                        background-size: cover;
                        background-position: top center;
                    }

                    .overlay{
                        width:33.3%;
                    }
                }                

                .first{
                    .bg{
                        background-image:url('../img/tostitos_thumbnail_dan.jpg');
                    }
                }

                .third{
                    .bg{
                        background-image:url('../img/tostitos_thumbnail_will.jpg');
                    }
                }

                .second{
                    .bg{
                        background-image:url('../img/tostitos_thumbnail_richard.jpg');
                    }
                }

                .fourth{
                    display: none;
                }
            }   
        }
    }
}