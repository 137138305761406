.credits{
	
	.item;
	background-color: @darkGrey;
	color:white;

	.creditsContainer{
		padding: 50px;

		@media @1024{
			padding-left: 200px;
		}
		.creditItem{
			
			opacity: 1;
			margin-top:10px;
			margin-bottom:10px;
			//margin-left: 35px;
			
			//padding:20px;
			box-sizing: border-box;

			width:100%;

			.title{
			
				width:100%;
				
				h2{
					display: inline-block;
					color:white;
					text-transform: uppercase;
					font-size: 20px;
					line-height: 30px;
					margin-right:10px;
					letter-spacing: 2px;
				}

				.name{
					display: inline-block;
					.dinLight;
					font-size: 16px;
					color: white;
				}
			}
		}	
	}
}