.juicyFruit{

	.top{
		.background{
			background-image:url('../img/JF_mid_casestudy.jpg');
		}
	}

	.infoTwo{
		.background{
			background-image:url('../img/juicyfruit_casestudy_bg.jpg');
		}
	}
	
	.infoThree{
		.background{
			background-image:url('../img/jf_casestudy_hero.jpg');
		}
		.content{
			.innerContent{
				.text{
					p{
					    font-size: 12px;
					    line-height: 15px;

					    @media @768{
					        font-size: 16px;
					        line-height: 18px;
					        padding-left: 50px;
					        padding-right: 50px;
					    }

					    @media @1024{
					        font-size: 18px;
					        line-height: 22px;
					    }

					    @media @1366{
					        padding-left:200px;
					        padding-right:200px;
					    }
					}
				}
			}
		}
	}

	.media{
		.mediaContainer{
			.imageWrapper{
				display: none;
			}	
		}
	}
}
