.casestudy{
    
    font-family: DINCondensed-Bold;
    -webkit-font-smoothing: antialiased;
    padding-top:50px;
    
    .intro{
        position: relative;
        overflow: hidden;
        .background{
            position: absolute;
            top: 0px;
            left: 0px;
            height: 100%;
            width: 100%;
            background-color: @darkerGrey;
            background-size:cover;
            background-repeat: no-repeat;
            background-position: top center;
        }
    }

    .info{
        position: relative;
        overflow: hidden;
        .background{
            position: absolute;
            top: 0px;
            left: 0px;
            background-size:cover;
            background-repeat: no-repeat;
            background-position: top center;
            height: 100%;
            width: 100%;
            .overlay{
                .layer;
                background-color: rgba(0,0,0,0.45);
            }
        }
        .content{
            position: relative;
        }
    }

    .top{
        .background{
            background-size:cover;
            background-repeat: no-repeat;
            background-position: top center;
            .overlay{
                .layer;
                background-color: rgba(0,0,0,0.4);
            }
        }

        .icon{
            .layer;
            font-size: 20px;
            color: white;
        }
        
        .visitSiteBtn{
            display: block;
            position: relative;
            margin-top: 20px;
            width: 150px;
            height: 50px;
            margin-left: 15px;
            color: white;
            background: transparent;
            border: 1px solid white;
            cursor: pointer;
            
            @media @960{
                margin-left: 38px;
            }
                
            .bgBtn{
                position: absolute;
                bottom: 0;
                width: 0px;
                height: 100%;
                background-color: white;
                transition:width 300ms @easeOutQuint;
                left: 0;
            } 

            a{
                text-decoration: none;
            }

            &.hover{
                .bgBtn{
                    width: 100%;
                }
                .textBtn{
                    color: #000;
                }
            }

            .textBtn{
                .layer;
                text-transform: uppercase;
                font-size: 24px;
                line-height: 55px;
                letter-spacing: 1px;
                font-family: DINCondensed-Bold;
                text-align: center;
            }
        }

        .content{
            display: table;
            .innerContent{
                display: table-cell;
                vertical-align: middle;
                
                h1{
                    font-size: 50px;
                    line-height: 42px;  
                    color: white;
                    text-transform: uppercase;
                    font-family: DINCondensed-Bold;
                    margin-left: 15px;
                    margin-top: 80px;


                    @media @960{
                        font-size: 70px;
                        line-height: 56px;
                        margin-left: 35px;
                        width: 778px;
                        margin-left: 35px;
                    }

                    @media @1366{
                        font-size: 90px;
                        line-height: 72px;
                        width: 628px;
                        width: 1010px;
                    }

                }

                .rule{
                    width: 218px;
                    border-bottom: 1px solid white;
                    margin-top: 0px;
                    margin-bottom: 5px;

                    @media @768{
                        width: 630px;
                    }

                }

                .cta{
                    text-transform: uppercase;
                    font-size: 17px;
                    color: white;
                    font-family: DINCondensed-Bold;
                    display: inline-block;
                    letter-spacing: 2px;
                    margin-left: 15px;
                    width: 260px;
                    line-height: 20px;
                    margin-top: 10px;

                    @media @768{
                        margin-left: 35px;
                    }
                }
            }
        }
    }

    .info{
        color: white;
        box-sizing:border-box;
        position: relative;

        .content{
            display: table;
            .innerContent{
                display: table-cell;
                vertical-align: middle;

                .text{
                    margin: 0 auto;
                    width: 100%;
                    
                    h1{
                        font-family: DINCondensed-Bold;
                        text-transform: uppercase;
                        font-size: 22px;
                        text-align: left;
                        line-height: 30px;
                        padding-left: 20px;
                        padding-right: 20px;
                        margin-bottom: 10px;
    
                        @media @768{
                            font-size: 26px;
                            padding-left: 50px;
                            padding-right: 50px;
                        }

                        @media @1024{
                            font-size: 35px;
                            letter-spacing: 3px;
                        }

                        @media @1366{
                            padding-left:200px;
                            padding-right:200px;
                        }
                    }

                    p{
                        .dinLight;
                        font-weight:bold;
                        font-size: 14px;
                        line-height: 17px;
                        padding-left: 20px;
                        padding-right: 20px;
                        text-align: left;
                        orphans: 4;

                        @media @768{
                            font-size: 16px;
                            line-height: 18px;
                            padding-left: 50px;
                            padding-right: 50px;
                        }

                        @media @1024{
                            font-size: 18px;
                            line-height: 22px;
                        }

                        @media @1366{
                            padding-left:200px;
                            padding-right:200px;
                        }
                    }

                    h2{
                        .dinLight;
                        font-size: 14px;
                        padding-left: 20px;
                        padding-right: 20px;
                        text-align: left;
                        
                        @media @768{
                            font-size: 16px;
                            padding-left: 50px;
                            padding-right: 50px;
                        }
                        
                        @media @1024{
                            font-size: 22px;
                        }

                        @media @1366{
                            padding-left:200px;
                            padding-right:200px;
                        }
                    }
                }

                .icon{
                    font-size: 100px;
                    margin-left: 15px;
                    margin-bottom: 15px;
                    line-height: 0px;
                    opacity: 0.8;

                    @media @768{
                        font-size: 190px;
                        position: relative;
                        float: right;
                        margin-left: 0px;
                        margin-bottom: 0px;
                        margin-right: 100px;
                    }
                }
            }
        }
    }

    .stats{
        .statsContainer{
            .statsItem{
                .icon{
                    .render{
                        background-image: url("../img/performance.svg");
                        width: 100px;
                        height: 100px;
                    }

                    .award{
                        background-image: url("../img/award.svg");
                        width: 100px;
                        height: 100px;
                    }

                    .engagments{
                        background-image: url("../img/social.svg");
                        width: 100px;
                        height: 100px;
                    }

                    .like{
                        background-image: url("../img/like_fb.svg");
                        width: 100px;
                        height: 100px;
                    }

                    .units{
                        background-image: url("../img/units.svg");
                        .ccnr;
                        width: 100px;
                        height: 100px;
                    }

                    .globe{
                        background-image: url("../img/globe.svg");
                        .ccnr;
                        width: 100px;
                        height: 100px;
                    }

                    .email{
                        background-image: url("../img/email.svg");
                        .ccnr;
                        width: 100px;
                        height: 100px;
                    }

                    .video{
                        background-image: url("../img/video.svg");
                        .ccnr;
                        width: 100px;
                        height: 100px;
                    }

                    .footage{
                        background-image: url("../img/footage.svg");
                        .ccnr;
                        width: 100px;
                        height: 100px;
                    }

                    .masses{
                        background-image: url("../img/masses.svg");
                        .ccnr;
                        width: 100px;
                        height: 100px;
                    }

                    .connect{
                        background-image: url("../img/connect.svg");
                        .ccnr;
                        width: 100px;
                        height: 100px;
                    }

                    .audience{
                        background-image: url("../img/audience.svg");
                        .ccnr;
                        width: 100px;
                        height: 100px;
                    }
                }
            }
        }
    }

    .media{
        .mediaContainer{
            .imageWrapper{
                display: none;
            }
        }
    }
}