.girlscount{
    
    .top{
        .background{
            background-image:url('../img/girlscount_hero_2.jpg');
        }
    }

    .infoTwo{
        .background{
            background-image:url('../img/girlscount_hero_1.jpg');
        }
    }
    
    .infoThree{
        .background{
            background-color:@gcPink;
            .overlay{
                display: none;
            }
        }
    }

    .media{
        .mediaContainer{
            .imageWrapper{
                display: none;
            }   
        }
    }

    .stats{
        .statsContainer{
            .statsItem{
                .headline{
                    span{
                        color: @gcPink;
                    }
                }
            }
        }
    }

    .press{
        .pressContainer{
            .pressItem{
                .title{
                    h2{
                        color: @gcPink;
                    }
                }
            }   
        }
    }
}