.remaxtools{

    .top{
        .background{
            background-image:url('../img/remax_hero_1.jpg');
            .overlay{
                opacity: 0.2;
            }
        }
    }

    .infoTwo{
        .background{
            background:@remaxRed;
            .overlay{
                opacity: 0;
            }
        }
    }
    
    .infoThree{
        .background{
            background-image:url('../img/remax_hero_2.jpg');
            .overlay{
                opacity: 0.3;
            }
        }
    }

    .media{
        .mediaContainer{
            .imageWrapper{
                display: none;
            }   
        }
    }

    .stats{
        .statsContainer{
            .statsItem{
                .headline{
                    span{
                        color: @remaxRed;
                    }
                }
            }
        }
    }

    .media{
        .mediaContainer{
            .imageWrapper{
                display: block;
                
                .thumbnail{
                    display: inline-block;
                    width:33.3%;
                    .bg{
                        width:33.3%;
                        background-size: cover;
                        background-position: top center;
                    }

                    .overlay{
                        width:33.3%;
                    }
                }                

                .first{
                    .bg{
                        background-image:url('../img/remax_thumbnail_dave.jpg');
                    }
                }

                .second{
                    .bg{
                        background-image:url('../img/remax_thumbnail_kelly.jpg');
                    }
                }

                .third{
                    .bg{
                        background-image:url('../img/remax_thumbnail_jeremy.jpg');
                    }
                }

                .fourth{
                    display: none;
                }
            }   
        }
    }
}