.fordEscapeTheRoom{
	.top{
		.background{
			background-image:url('../img/ford_casestudy_hero@2x.jpg');
		}
	}	
		
	.infoTwo{
		.background{
			background-image:url('../img/ford_hero@2x.jpg');
		}
	}
	
	.infoThree{
		.background{
			background-image:url('../img/ford_cs_bg@2x.jpg');
		}
	}
	
	.stats{
		.statsContainer{
			.statsItem{
				.headline{
					span{
						color: @fordBlue;
					}
				}
			}
		}
	}

	.press{
		.pressContainer{
			.pressItem{
				.title{
					h2{
						color: @fordBlue;
					}
				}
			}	
		}
	}
}
