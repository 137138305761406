.laheyVotes{

	.top{
		.background{
			background-image:url('../img/layhey_casestudy.jpg');
		}
	}

	.infoTwo{
		.background{
			background-image:url('../img/motiontrack_hero_feature.jpg');
		}
	}
	
	.infoThree{
		.background{
			background-image:url('../img/mrlahey_casestudy.jpg');
		}
	}

	.media{
		.mediaContainer{
			.imageWrapper{
				display: none;
			}	
		}
	}

	.press{
		.pressContainer{
			.pressItem{
				.title{
					h2{
						color: #DE1F27;
					}
				}
			}	
		}
	}
}
