@keyframes arrowHover {
    0%   {transform: translateX(0px)}
    50%  {transform: translateX(15px)}
    100% {transform: translateX(0px)}
}

@keyframes arrowHoverReverse {
    0%   {transform: translateX(0px)}
    50%  {transform: translateX(-15px)}
    100% {transform: translateX(0px)}
}

@keyframes fadeIn {
    0%   {
    		opacity: 0;
    	}
    100%  {
    	opacity: 0.6;
    }
}
@keyframes fadeInCta {
    0%   {
    		opacity: 0;
    	}
    100%  {
    	opacity: 1;
    }
}
@keyframes hexFadeIn {
    0%   {
    		opacity: 0;
    	}
    100%  {
    	opacity: 0.6;
    }
}


@keyframes ruleGrow {
    0% {
    	width:0%;
		opacity: 0;
    }
    100% {
    	width:100%;
    	opacity: 1;
    }
}

@keyframes kenburns {
    from {
		transform: scale(1.01);
	  -ms-transform: scale(1.01); /* IE 9 */
	  -webkit-transform: scale(1.01); /* Safari and Chrome */
	  -o-transform: scale(1.01); /* Opera */
	  -moz-transform: scale(1.01);
    }
    to {
     	transform: scale(1.2);
        -ms-transform: scale(1.2); /* IE 9 */
        -webkit-transform: scale(1.2); /* Safari and Chrome */
        -o-transform: scale(1.2); /* Opera */
        -moz-transform: scale(1.2);
    }
}

.home{
	-webkit-font-smoothing: antialiased;
	font-family: DINCondensed-Bold;
	.top{

		.content{

			position: absolute;
			h1{
				font-family: DINCondensed-Bold;
				text-transform: uppercase;
				margin: 0 auto;
				font-size: 44px;
				margin-top: 80px;
    			width: 320px;
    			line-height: 40px;
    			margin-left: 15px;
    			color: white;
    			animation: fadeIn 2s ease 1;

    			@media @768{
    				font-size: 120px;
    				line-height: 90px;
    			}
			}

			.rule{
    			margin-bottom: 10px;
    			margin-top: 5px;

    			@media @768{
    				margin-top: 0px;
    			}

			}
			.ctaContainer{
				width: 320px;
				animation: fadeInCta 2s ease 1;
				@media @500{
					width: 100%;
				}
				.cta{
					text-transform: uppercase;
					font-size: 17px;
					margin-left: 20px;
					letter-spacing: 2px;
					color: @blue;
					display: inline-block;
					cursor: pointer;
					a{
						color: #3588BA;
						text-decoration: none;

					}

					@media @768{
						margin-left: 55px;
						font-size: 20px;
					}

					@media @1366{
						font-size: 25px;
					}
				}

				.arrow{
					color: @blue;
	    			font-size: 15px;
	    			display: inline-block;
	    			margin-left: 5px;

	    			@media @1366{
	    				font-size: 17px;
	    			}
				}
			
				&:hover{
					.arrow{
						.fa{
							// animation: arrowHover 0.55s @easeInOutQuad 0.2s 1 forwards;
						}
					}
				}
			}
		}
	}

	.top{
		height: 100%;
		width: 100%;
		position: relative;
			height: 100%;
			width: 100%;
		video{
	 	   position: absolute;
			height: 100%;
			width: 100%;
		}
		.background{
			position: absolute;
			.at2x('../img/destinybg.jpg', 1920px, 948px);
			.cover;
			.ccnr;
			overflow: hidden;
			background-color: black;

			@media @768{
				background: url("");
				
				width: 100%;
				height: 100%;
			}
			.overlay{
				position: absolute;
				.layer;
				background-color: rgba(0,0,0,0.6);
			}
		}
		.content{
			display: table;
			width: 100%;
			height: 100%;
			.innerContent{
				display: table-cell;
				vertical-align: middle;
	
				h1{
				    opacity: 0.6;
				    margin-top: 80px;
				    color: #fff;

					@media @768{
					    width: 686px;
    					font-size: 88px;
    					line-height: 74px;
					    margin-top: 80px;
					    margin-left: 50px;
					}

					@media @1366{
						width: 1098px;
					    font-size: 150px;
					    line-height: 124px;
					}

				}

				.ruleHolder{
					width: 260px;
					opacity: 1;
					
					@media @768{
						width:700px;
					}

					@media @1200{
						width: 700px;
					}

					@media @1366{
						width: 1130px;
						margin-bottom: 20px;
					}

					.rule{
						border-bottom: 1px solid white;
						@media @768{
							opacity: 0;
						}

						&.grow{
							animation: ruleGrow 1s @easeInQuad 1;
							opacity: 1;
						}						
					}
				}
				.hex{
					opacity: 0;
					@media @768{
						width: 300px;
						height: 140px;
						margin: auto;
						position: absolute;
						bottom: -60px;
						left: 0;
						right: 0;
						z-index: 2;
						background-image: url("../img/hex_white.svg");
						.ccnr;
						animation: hexFadeIn 1s @easeInQuad forwards;
						animation-delay: 500ms;
					}
				}	
			}
		}
	}

	.header{
		height: 150px;
		background-color: @darkerGrey;

		.content{
			display: table;
			.innerContent{
				display: table-cell;
				vertical-align: middle;

				h1{
					font-family: DINCondensed-Bold;
					text-transform: uppercase;
				    font-size: 17px;
				    text-align: left;
				    letter-spacing: 3px;
				    //margin-left: 15px;
					line-height: 20px;
					width: 225px;
					margin: 0 auto;
					margin-bottom: 5px;
					color: white;

					@media @500{
						width: 375px;
					}

					@media @768{
						width: 580px;
						font-size: 65px;
						line-height: 49px;
					}
				}

				.rule{
					border-bottom: 1px solid white;
					width: 184px;
					margin: 0 auto;
					margin-top: 20px;

					@media @1024{
						width: 170px;
					}
				}
			}
		}	
	}
	
	.overview{
		// max-height: 356px;
		//height: 400px;
		//max-height: 500px;
		
		@media @500{
			//height: 350px;	
		}

		@media @768{
			//height: 500px;
		}
		// @media @768{
		// 	height: 165px !important;	
		// }

		.content{
			color: white;
			display: table;

			.innerContent{
				display: table-cell;
				vertical-align: middle;

				.textWrap{
						padding-left: 20px;
				    	padding-right: 20px;

					@media @768{
						max-width: 330px;
						padding-left: 40px;
				    	padding-right: 40px;
					}

					@media @1024{
						max-width: 420px;
					}
					h2{
						font-family: DINCondensed-Bold;
						text-transform: uppercase;
					    font-size: 17px;
					    text-align: left;
					    letter-spacing: 3px;
					    //margin-left: 15px;
						line-height: 20px;
		    			//width: 225px;
		    			margin: 0 auto;

		    			// @media @500{
		    			// 	width: 375px;
		    			// }

		    			// @media @768{
		   				// 	width: 240px;
		   				// }

		    			@media @960{
		    				font-size: 28px;
		    				line-height: 29px;
		    			}

					    // @media @1024{
		    			// 	width: 310px;

					    // }

					    //  @media @1366{
		    			// 	width: 360px;

					  // }
					}

					p{
						//width: 225px;
		   				//margin-left: 15px;
		   				.dinLight;
		   				font-size: 14px;
		   				line-height: 17px;
		   				margin: 0 auto;

		   		// 		@media @500{
							// width: 375px;
		   		// 		}

		   		// 		@media @768{
		   		// 			width: 240px;
		   		// 		}

		   		// 		@media @960{
		   		// 			width:260px;
		   		// 		}

		   		// 		@media @1024{
		   		// 			width: 310px;
		   		// 		}

		   				@media @1366{
							///width: 360px;
							font-size: 17px;
							line-height: 19px;
		   				}
					}

					.ctaWrap{
						margin-top: 10px;
						.cta{
							text-transform: uppercase;
							font-size: 14px;
							margin-top: 10px;
							letter-spacing: 2px;
							display: inline-block;
							cursor: pointer;
							margin: 0 auto;
		    				line-height: 18px;
		    				width: 100%;
		    				color: white;

							@media @1024{
								font-size: 17px;
								margin-top: 5px;

							}

							a{
								text-decoration: none;
								color: white;
							}
						
						}

						.arrow{
							color: white;
			    			font-size: 15px;
			    			display: inline-block;
			    			margin-left: 5px;
						}
					}
				}
			}
			&:hover{
				.arrow{
					.fa{
						// animation: arrowHover 0.55s @easeInOutQuad 0.1s 1 forwards;
					}
				}
			}
		}
	}

	.even{
		.background{
			background-color: @darkerGrey;
		}
	}

	.odd{
		.background{
			background-color: @darkerGrey;
		}
	}

	.text{
		.background{
			background-color:@blue;

		}

		.content{
			color: white;
			display: table;

			.innerContent{
				display: table-cell;
				vertical-align: middle;

				h3{
					font-family: DINCondensed-Bold;
					text-transform: uppercase;
				    font-size: 45px;
	    			line-height: 37px;
				    text-align: left;
				    letter-spacing: 3px;
				    margin-left: 35px;
	    			width: 260px;

	    			@media @768{
	    				line-height: 30px;
    					width: 197px;
    					font-size: 35px;
	    			}

				    @media @1024{
				    	font-size: 49px;
	    				line-height: 44px;
	    				width: 260px;

				    }

				}

				.rule{
					border-bottom: 1px solid white;
					margin-top: 5px;
					margin-bottom: 10px;
					width: 240px;

					@media @768{
						width: 203px;
					}

					@media @1024{
						width:258px;
					}
				}

				p{
					width: 200px;
	   				margin-left: 35px;
	   				.dinLight;
	   				font-size: 14px;
	   				line-height: 17px;
	   				margin-right: 5px;


	   				@media @1024{
	   					font-size: 17px;
	   					line-height: 19px;
	   					width:270px;
	   				}

	   				@media @1366{
						width: 361px;
	   				}
				}

				.cta{
					text-transform: uppercase;
					font-size: 17px;
					margin-left: 35px;
					margin-top: 10px;
					letter-spacing: 2px;
					display: inline-block;
					cursor: pointer;

					@media @1024{
						font-size: 20px;
					}

					a{
						text-decoration: none;
						color: white;
					}
				
				}

				.arrow{
					color: white;
	    			font-size: 15px;
	    			display: inline-block;
	    			margin-left: 5px;
				}

			}
			&:hover{
				.arrow{
					.fa{
						// animation: arrowHover 0.55s @easeInOutQuad 0.1s 1 forwards;
					}
				}
			}
		}
	}

	.feature{
		overflow: hidden;
		a{
			text-decoration: none;
			color: white;
		}

		.featureContent{
			height: 100%;
			width: 100%;
			.innerContent{
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				margin: auto;
				text-align: center;
			    height: 184px;
			    @media @768{
			    	height: 225px; 	
			    }
				span{
					font-size: 45px;
					color: #fff;
					text-align: left;
					position: relative;
					line-height: 1;
					margin-left: 35px;
				}			
				h3{
					font-family: DINCondensed-Bold;
					text-transform: uppercase;
				    color: white;
				    margin-top: 20px;
				    font-size: 18px;
				    text-align: left;
				    letter-spacing: 3px;
					line-height: 38px;
	    			width: 260px;
	    			margin-left: 35px;

	    			@media @768{
	    				line-height: 20px;
    					width: 100%;
	    			}

	    			@media @1024{
	    				font-size: 28px;
	    				margin-bottom: 10px;

	    			}
				}

				.rule{
					border-bottom: 1px solid white;
					margin-top: 5px;
					margin-bottom: 10px;
					width: 200px;
				}

				p{
					width: 75%;
	   				margin-left: 35px;
	   				.dinLight;
	   				font-size: 14px;
	   				line-height: 17px;
	   				color:white;
	   				text-align: left;


	   				@media @1024{
	   					font-size: 17px;
	   					line-height: 19px;
	   					width:270px;
	   				}

	   				@media @1366{
						width: 361px;
	   				}
				}

				.ctaWrap{
					text-align: left !important;
					.cta{
						cursor: pointer;
						text-transform: uppercase;
						font-size: 14px;
						margin-left: 35px;
						margin-top: 10px;
						letter-spacing: 2px;
						display: inline-block;
						width: 60%;
    					line-height: 18px;

						a{
							text-decoration: none;
							color: white;
						}

						@media @1024{
							font-size: 20px;
							width: 40%;
						}		
					}

					.arrow{
						color: white;
		    			font-size: 16px;
		    			vertical-align: bottom;
		    			display: inline-block;
		    			margin-left: 5px;
					}
				}
			}	
		}

		.rollIcon{
			position: relative;
			.ccnr;
			height: 70px;
    		width: 70px;
    		left: 35px;
		}	
	}


	.social{

		.rollIcon{
			background-image: url("../img/social.svg");
		}
		
		.background{
			.at2x('../img/socialconnect_feature.jpg', 640px, 640px);
			.cover;
			background-position: top center;

			.overlay{
				.layer;
				background-color: rgba(0,0,0,0.6);
			}
		}

		.background{
			transform: scale(1.0);
			transition:all 1000ms @easeOutQuad;
			cursor: pointer;
		}
		&:hover{
			.background{
 			    transform: scale(1.10);
				// animation: kenburns 7s ease infinite;
			}
		}
	}


	.rotoscoping{

		.rollIcon{
			background-image: url("../img/roto.svg");
		}
		
		.background{
			.at2x('../img/roto_letgo.jpg', 640px, 640px);
			.cover;
			background-position: top center;
			transform: scale(1.0);
			transition:all 1000ms @easeOutQuad;
			cursor: pointer;

			.overlay{
				.layer;
				background-color: rgba(0,0,0,0.6);
			}
		}

		&:hover{
			.background{
 			    transform: scale(1.10);
				// animation: kenburns 7s ease infinite;
			}
		}
	}


	.tracking{

		.rollIcon{
			background-image: url("../img/motion_track.svg");
		}
		
		.background{
			.at2x('../img/motiontrack_feature.jpg', 640px, 640px);
			.cover;
			background-position: top center;
			transform: scale(1.0);
			transition:all 1000ms @easeOutQuad;
			cursor: pointer;

			.overlay{
				.layer;
				background-color: rgba(0,0,0,0.6);
			}

		}

		&:hover{
			.background{
 			    transform: scale(1.10);
				// animation: kenburns 7s ease infinite;
			}
		}
	}


	.dynamic{
		
		.rollIcon{
			background-image: url("../img/text.svg");
		}
		
		.background{
			.at2x('../img/dynamictext_letgo.jpg', 640px, 640px);
			.cover;
			background-position: top center;
			transform: scale(1.0);
			transition:all 1000ms @easeOutQuad;
			cursor: pointer;

			.overlay{
				.layer;
				background-color: rgba(0,0,0,0.6);
			}

		}

		&:hover{
			.background{
 			    transform: scale(1.10);
				// animation: kenburns 7s ease infinite;
			}
		}
	}

	.swap{
		
		.rollIcon{
			background-image: url("../img/clip_swap.svg");
		}
		
		.background{
			.at2x('../img/clipswap_seances.jpg', 640px, 640px);
			.cover;
			background-position: top center;
			transform: scale(1.0);
			transition:all 1000ms @easeOutQuad;
			cursor: pointer;

			.overlay{
				.layer;
				background-color: rgba(0,0,0,0.6);
			}
		}

		&:hover{
			.background{
 			    transform: scale(1.10);
				// animation: kenburns 7s ease infinite;
			}
		}
	}

	.bottom{
		height: 800px;

		@media @768{
			height: 400px;
		}
		.background{
			position: relative;
			background-color: #131313;
			
			.hex{
				position: absolute;
				.at2x('../img/hex.png', 842px, 978px);
				.cover;
				height: 100%;
    			width: 100%;
				margin-top: 20px;

				&.showHex{
					opacity: 1;
					left: 0;
					transition:all 1000ms @easeOutQuad;
				}

				@media @768{
					width: 480px;
					opacity: 0;
					left: -480px;
				}

				@media @1366{
					height: 100%;
    				width: 580px;
					width: -580px;
				}

				@media @1600{
					height: 100%;
    				width: 40%; 
    				width: -1000px;
				}
			}
		}
		.content{
			display: table;	
			.innerContent{
				display: table-cell;
				vertical-align: middle;
				.left{
					@media @1366 {
						float: left;
					}

					h3{
						font-family: DINCondensed-Bold;
						text-transform: uppercase;
						margin: 0 auto;
						font-size: 60px;
		    			width: 310px;
		    			line-height: 48px;
		    			margin-left: 15px;
		    			letter-spacing: 2px;
		    			color: white;

		   				@media @768{
					   		font-size: 45px;
						    line-height: 38px;
						    margin-left: 50px;
						    margin-top: 30px;
						    width: 300px;
					    }

		    			@media @960{
		    				font-size: 79px;
						    width: 480px;
						    line-height: 60px;
		    			}

		    			@media @1366{
		    				font-size: 90px;
    						width: 527px;
    						line-height: 70px;
		    			}

		    			@media @1600{
		    				margin-left: 100px;
		    			}
					}

					.rule{
						width: 295px;
						border-bottom: 1px solid white;

						@media @768{
							width: 312px;
						}

						@media @960{
							width: 488px;
						}

						@media @1366{
							width: 548px;
						}

						@media @1600{
							width: 598px;
						}
					}

					.ctaContainer{
						text-transform: uppercase;
						font-size: 17px;
						margin-left: 15px;
						letter-spacing: 2px;
						color: @blue;
						cursor: pointer;
						margin-top: 10px;

						
						.cta{
							display: inline-block;
							a{
								text-decoration: none;
								color: @blue;
							}
						}

						.arrow{
	    					font-size: 15px;
	    					display: inline-block;
	    					margin-left: 10px;

						}

						@media @768{
							margin-left: 50px;
						}

						@media @1600{
							margin-left: 100px;
						}
					}
				}

				.right{
					width: 310px;
					margin: 0 auto;
					margin-top: 60px;

					@media @768{
						//float: right
						margin: 0 auto;
						width:  606px;
					}

					@media @1024{
						width: 610px;
					}

					@media @1366{
						width: 890px;
						display: inline-block;
						float: right;
					}

					@media @1600{
						margin-right: 50px;
		    		}	

					.benefits{
						.benItems{
							margin-bottom: 20px;

							@media @1024{
							margin-top: 20px;	
							}

							@media @1366{
								margin-right: 20px;
								margin-left: 20px;
							}
							
							h3{
								font-size: 16px;
							    margin-top: 10px;
							    width: 136px;
							    line-height: 17px;
							    margin-bottom: 5px;
							    color: white;
							    font-family: DINCondensed-Bold;
								text-transform: uppercase;
								margin-left: 15px;
								letter-spacing: 2px;

								@media @1366{
									font-size: 20px;
									line-height: 20px;
									width: 100%;
								}
							}

							p{
								.dinLight;
								color: white;
			   					font-size: 14px;
			   					line-height: 17px;
		    					margin-left: 15px;
		    					width: 136px;

		    					@media @1366{
		    						font-size: 17px;
		    						line-height: 19px;
		    						width: 166px;
		    					}
							}

							span{
								font-size: 45px;
								color:white;
								text-align: left;
								margin-left: 20px;
							}
						}

						.benIcon{
							margin-left: 20px;
							margin-bottom: 5px;
						}

						.dev{
							display: inline-block;

							.benIcon{
								position: relative;
								background-image: url("../img/pay.svg");
								.ccnr;
								height: 55px;
					    		width: 55px;

					    		@media @1024{
									height: 70px;
					    			width: 70px;
					    		}

					    		@media @1366{
									height: 90px;
					    			width: 90px;
					    		}
							}
							
						}

						.flex{
							display: inline-block;

							.benIcon{
								position: relative;
								background-image: url("../img/key.svg");
								.ccnr;
								height: 55px;
					    		width: 55px;

					    		@media @1024{
									height: 70px;
					    			width: 70px;
					    		}

					    		@media @1366{
									height: 90px;
					    			width: 90px;
					    		}
							}
						}

						.quality{
							display: inline-block;

							.benIcon{
								position: relative;
								background-image: url("../img/fast.svg");
								.ccnr;
								height: 55px;
					    		width: 55px;

					    		@media @1024{
									height: 70px;
					    			width: 70px;
					    		}

					    		@media @1366{
									height: 90px;
					    			width: 90px;
					    		}
							}
						}

						.masses{
							display: inline-block;

							.benIcon{
								position: relative;
								background-image: url("../img/engagment.svg");
								.ccnr;
								height: 55px;
					    		width: 55px;

					    		@media @1024{
									height:70px;
					    			width: 70px;
					    		}

					    		@media @1366{
									height: 90px;
					    			width: 90px;
					    		}
							}
						}
					}
				}
			}
	 	}
    }

	.soapbox{
		.background{
			background-color:@blue;

		}

		.content{
			color: white;
			display: table;

			.innerContent{
				display: table-cell;
				vertical-align: middle;
				.headline{
					font-family: DINCondensed-Bold;
					text-transform: uppercase;
				    font-size: 45px;
	    			line-height: 37px;
				    text-align: left;
				    letter-spacing: 3px;
				    margin-left: 35px;
					line-height: 38px;
	    			width: 215px;

	    			@media @1024{
	    				font-size: 60px;
					    line-height: 48px;
					    width: 281px;
	    			}
				}

				.rule{
					border-bottom: 1px solid white;
					margin-top: 5px;
					margin-bottom: 10px;
					width: 242px;

					@media @1024{
						width: 300px
					}
				}


				.cta{
					width: 187px;
	   				margin-left: 35px;
	   				.dinLight;
	   				font-size: 14px;

	   				@media @960{
	   					width: 241px;
	   					font-size: 15px;
	   				}

	   				@media @1366{
						width: 361px;
	   				}
				
				}

				.arrow{
					color: white;
	    			font-size: 15px;
	    			display: inline-block;
	    			margin-left: 5px;
				}

			}
		}
	}

	.article{
		overflow: hidden;
		cursor:pointer;
		
		.content{
			display: table;
			.innerContent{
				display: table-cell;
				vertical-align: middle;
				color: white;

				h5{
					font-size: 16px;
	    			margin-top: 0;
	    	    	width: 80%;
    				line-height: 20px;
				    margin-bottom: 5px;
				    letter-spacing: 2px;
				    text-transform: uppercase;
				    margin-left: 35px;
				    margin-top: 5px;

				    @media @960{
				    	font-size: 28px;
				    	line-height: 26px;
				    	width: 70%;
				    }

				}

				.rule{
					border-bottom: 1px solid white;
					margin-top: 5px;
					margin-bottom: 5px;
					width: 200px;

				}

				p{
					width: 80%;
		   			margin-left: 35px;
		   			.dinLight;
		   			font-size: 14px;
		   			line-height: 17px;

		   			@media @1366{
		   				font-size: 17px;
		   				line-height: 19px;
		   				width: 70%;
		   			}

				}

				.ctaContainer{

					.cta{
						margin-left: 35px;
						text-transform: uppercase;
						letter-spacing: 2px;
						margin-top: 10px;
						display: inline-block;
						a{
							text-decoration: none;
							color: white;
						}
					}

					.arrow{
						display: inline-block;
						font-size: 13px;
			    		display: inline-block;
			    		margin-left: 5px;
					}

					&:hover{
						.arrow{
							.fa{
								animation: arrowHover 0.55s @easeInOutQuad 0.1s 1 forwards;
							}
						}
					}
				}
			}
		}
		.background{
			transform: scale(1.0);
			transition:all 1000ms @easeOutQuad;
		}
		&:hover{
			.background{

 			    transform: scale(1.10);
				// animation: kenburns 7s ease infinite;
			}
		}
	}

	.articleOne{
		.background{
			transform-origin:center;

			//tostitos
			.at2x('../img/verizon_nfl_news_bg.jpg', 584px, 482px);
			.cover;
			.ccnr;

			.overlay{
				.layer;
				background-color: rgba(0,0,0,0.6);
			}
		}
	}

	.articleTwo{
		.background{

			//havenlife
			.at2x('../img/hl_news_bg.jpg', 584px, 482px);
			.cover;
			.ccnr;

			.overlay{
				.layer;
				background-color: rgba(0,0,0,0.5);
			}

			.innerContent{
				h5{
					margin-top: 270px;
				}
			}
		}
	}

	.articleThree{
		.background{

			//CES
			.at2x('../img/ces_news_bg.jpg', 584px, 482px);
			.cover;
			.ccnr;

			.overlay{
				.layer;
				background-color: rgba(0,0,0,0.4);
			}
		}
	}

	.articleFour{

		.background{

			//NRF
			.at2x('../img/nrf_news_bg.jpg', 584px, 482px);
			.cover;
			.ccnr;

			.overlay{
				.layer;
				background-color: rgba(0,0,0,0.4);
			}
		}
	}
	
	.articleFive{
		
		.background{
			.at2x('../img/remax_news_bg.jpg', 584px, 482px);
			.cover;
			.ccnr;

			.overlay{
				.layer;
				background-color: rgba(0,0,0,0.3);
			}
		}
	}

	.marriott{
		
		.background{
			.at2x('../img/marriott_news_bg.jpg', 584px, 482px);
			.cover;
			.ccnr;

			.overlay{
				.layer;
				background-color: rgba(0,0,0,0.4);
			}
		}
	}

	.vzThanks{
		
		.background{
			.at2x('../img/vzthanks_news_bg.jpg', 584px, 482px);
			.cover;
			.ccnr;

			.overlay{
				.layer;
				background-color: rgba(0,0,0,0.2);
			}
		}
	}

	.girlsCount{
		
		.background{
			.at2x('../img/girlscount_news_bg.jpg', 584px, 482px);
			.cover;
			.ccnr;

			.overlay{
				.layer;
				background-color: rgba(0,0,0,0.3);
			}
		}
	}	
}