
.showcase{
	-webkit-font-smoothing: antialiased;
	
	/*---------- HERO ----------*/
	.hero{
		.content{
			display: table;
			.innerContent{
				display: table-cell;
				vertical-align: middle;
				
				h1{
					font-size: 55px;
					color: white;
					line-height: 48px;
					text-transform: uppercase;
					font-family: DINCondensed-Bold;
					width: 260px;

				}

				h2{
					color:white;
					font-size: 17px;
		    		letter-spacing: 2px;
		    		font-family: DINCondensed-Bold;
		    		text-transform: uppercase;
		    		display: block;

		    		@media @1024{
		    			font-size: 28px;
		    		}
				}

				.ctaContainer{

					.cta{
						text-transform: uppercase;
						font-size: 17px;
						color: @red;
						font-family: DINCondensed-Bold;
						display: inline-block;
						letter-spacing: 2px;

						a{
							text-decoration: none;
							color: @red;
						}
					}

					.arrow{
						color: @red;
		    			font-size: 15px;
		    			display: inline-block;
		    			margin-left: 5px;
				
					}
					&:hover{
						.arrow{
							.fa{
								animation: arrowHover 0.55s @easeInOutQuad 0.1s 1 forwards;
							}
						}
					}
				}
			}
		}

		&.default{

			&.lollipop{
				.background{
					background-image:url('../img/lollipop_bg@2x.jpg');
				}	
			}
			
			&.letgo{
				.background{
					background-image:url('../img/letgo_hero_action@2x.jpg');
				}	
			}
			
			&.marriott{
				.background{
					background-image:url('../img/marriott_hero_2.jpg');
				}	
				.overlay{
					opacity:0.2;
				}
			}

			&.tostitos{
				.background{
					background-image:url('../img/tostitos_hero@2x.jpg');
				}	
			}

			&.legendofyou{
				.background{
					background-image: url('../img/destiny_casestudy@2x.jpg');
				}
			}

			height: 370px;

			.background{
				.cover;
				.overlay{
					.layer;
					background-color: rgba(0,0,0,0.4);
					background-position: top center;
					background-size:cover;
					background-repeat: no-repeat;
				}
			}

			.content{
				display: table;
				.innerContent{
					display: table-cell;
					font-family: DINCondensed-Bold;
					text-transform: uppercase;
					vertical-align: middle;
					h1{
						margin-top: 150px;
		    			margin-left: 20px;
		    			width: 300px;

		    			@media @500{
		    				font-size: 90px;
		    				line-height: 72px;
		    				width: 430px;
		    				margin-top: 85px;
		    			}

						@media @768{
							width:540px;
							font-size: 120px;
	    					line-height: 92px;
	    					margin-left: 50px;
						}

						@media @1366{
							width: 845px;
	    					font-size: 190px;
	    					line-height: 144px;
						}

					}

					h2{
	    				margin-left: 25px;

	    				@media @768{
	    					margin-left: 55px;
	    				}
					}

					.rule{
						width: 165px;
						border-bottom: 1px solid white;
	    				margin-top: 0px;
						margin-bottom: 5px;

						@media @1024{
							width: 265px;
						}

						@media @1366{
	   						margin-bottom: 10px;
	    					margin-top: 5px;
						}
					}

					.cta{
						cursor: pointer;
						margin-left: 25px;

						@media @768{
							margin-left: 55px;
						}
					}
		
				}
			}
		}
	}
	
	/*---------- TEXT ITEM ----------*/
	.text{
		.background{
			background-color:@red;

		}

		.content{
			color: white;
			display: table;
			font-family: DINCondensed-Bold;

			.innerContent{
				display: table-cell;
				vertical-align: middle;
				h1{
					font-family: DINCondensed-Bold;
				    text-transform: uppercase;
				    font-size: 35px;
				    text-align: left;
				    letter-spacing: 3px;
				    margin-left: 35px;
				    line-height: 31px;
				    width: 190px;

	    			@media @768{
	    				line-height: 26px;
					    width: 150px;
					    font-size: 25px;
	    			}

				    @media @1024{
				    	font-size: 41px;
					    line-height: 40px;
					    width: 220px;

				    }

				}

				.rule{
					border-bottom: 1px solid white;
					margin-top: 5px;
					margin-bottom: 10px;
					width: 178px;

					@media @768{
						width: 150px;
					}

					@media @1024{
						width: 198px;
					}
				}

				p{
					width: 200px;
	   				margin-left: 35px;
	   				.dinLight;
	   				font-size: 14px;


	   				@media @1024{
	   					font-size: 17px;
	   					width:270px;
	   				}

	   				@media @1366{
						width: 361px;
	   				}
				}

				.ctaContainer{

					.cta{
						cursor: pointer;
						text-transform: uppercase;
						font-size: 17px;
						margin-left: 35px;
						letter-spacing: 2px;
						display: inline-block;

						@media @1024{
							font-size: 20px;
						}

						a{
							text-decoration: none;
							color: white;
						}
					
					}

					.arrow{
						color: white;
		    			font-size: 15px;
		    			display: inline-block;
		    			margin-left: 5px;
					}

					&:hover{
						.arrow{
							.fa{
								animation: arrowHover 0.55s @easeInOutQuad 0.2s 1 forwards;
							}
						}
					}

				}

			}
		}
	}
	
	/*---------- PROJECT ----------*/
	.project{
		overflow: hidden;
		
		&.vzthanks{
			.background{
				background-image: url('../img/vzthanks@2x.jpg');
			}
		}

		&.girlscount{
			.background{
				background-image: url('../img/girlscount@2x.jpg');
			}
		}

		&.vzxmas{
			.background{
				background-image: url('../img/vzxmas@2x.jpg');
			}
		}

		&.placeholder{
			.content{
				display: none;
			}
		}
		
		&.tripadvisor{
			.background{
				background-image: url('../img/tripadvisor@2x.jpg');
			}
		}

		&.juicyFruit{
			.background{
				background-image:url('../img/juicyfruit@2x.jpg');
			}
		}

		&.fordEscape{
			.background{
				background-image:url('../img/ford_showcase@2x.jpg');
			}
		}  

		&.tMobile{
			.background{
				background-image:url('../img/tmobile@2x.jpg');
			}
		}

		&.destiny{
			.background{
				background-image:url('../img/takenking@2x.jpg');
			}
		}

		&.lahey{
			.background{
				background-image:url('../img/lahey@2x.jpg');
			}
		} 

		&.petrJohn{
			.background{
				background-image:url('../img/petrjohn@2x.jpg');
			}
		}  

		&.lollipop{
			.background{
				background-image:url('../img/lollipop_showcase@2x.jpg');
			}
		}

		&.lp{
			.background{
				background-image:url('../img/lp_project@2x.jpg');
			}
		}

		&.nfb{
			.background{
				background-image:url('../img/seances_project@2x.jpg');
			}
		}

		&.lahey{
			.background{
				background-image:url('../img/lahey@2x.jpg');
			}
		}

		&.dart{
			.background{
				background-image:url('../img/dart@2x.jpg');
				background-position:bottom center !important;
			}
		}

		&.ihg{
			.background{
				background-image:url('../img/ihg@2x.jpg');
			}
		}

		&.orsted{
			.background{
				background-image:url('../img/orsted@2x.jpg');
			}
		}
		
		.background{
			.cover;
			background-position: top center;
			.overlay{
				.layer;
				background-color: rgba(0,0,0,0.4);
			}
		}
		
		&.doublewide{

			@media @768{
				width:66.65% !important;
			}
		}

		.content{
			overflow: hidden;
			display: table;

			.showcaseOverlay{
				display: table;
				font-family: DINCondensed-Bold;
				//background-color: @red;
				width: 100%;
				height: 100%;
				position: absolute;
				color: white;
				//display: none;
				z-index: 100;
				//transition:left 600ms @easeOutQuad;
				@media @960{
					//display: block;
					//opacity: 0.8;
				}
				.innerRoll{
					position: absolute;
					margin: auto;
					top: 0;
					bottom: 0;
					width:100%;
					height: 110px;

					@media @960{
						height: 170px;
					}

					h1{
						text-transform: uppercase;
					    font-size: 20px;
		    			line-height: 17px;
					    text-align: left;
					    letter-spacing: 3px;
					    margin-left: 35px;
		    			width: 85%;

					    @media @1024{
		    				line-height: 24px;
		    				//width: 260px;
		    				font-size: 28px;

					    }

					}

					.rule{
						border-bottom: 1px solid white;
						margin-top: 5px;
						margin-bottom: 10px;
						width: 200px;
					}

					.title{
						text-transform: uppercase;
					    font-size: 14px;
		    			line-height: 14px;
					    text-align: left;
					    letter-spacing: 3px;
		    			width: 95%;
		    			margin-left: 35px;

		    			@media @768{
		    				line-height: 21px;
    						//width: 217px;
	
		    			}

					    @media @1024{
					    	font-size: 18px;
		    				line-height: 18px;
		    				//width: 260px;

				    	}
					}

					p{
						width: 85%;
		   				margin-left: 35px;
		   				.dinLight;
		   				font-size: 14px;
		   				display: none;

		   				@media @960{
		   					display: block;
		   				}


		   				@media @1024{
		   					font-size: 17px;
		   					//width:270px;
		   				}

		   				@media @1366{
							//width: 361px;
		   				}
					}

					.ctaBtn{
						cursor: pointer;
						width: 130px;
	    				height: 33px;
	   					border: 1px solid #fff;
	    				margin-top: 10px;
	    				position: relative;
	    				transition:color 300ms @easeInQuad;
	    				color: white;
	    				margin-left: 35px;
	    				display: block;
	    				margin-bottom: 10px;

	    				.bg{
	    					@media @768{
		    					bottom: 0;
		    					width: 0;
		    					height: 100%;
		    					background-color: white;
		    					z-index: 1;
		    					position: absolute;
		    					transition:width 300ms @easeInQuad;
	    					}
	    				}

	    				a{
	    					color: white;
    						text-decoration: none;
	    				}

						h2{
							text-transform: uppercase;
							font-size: 15px;
							left: 0;
							right: 0;
							margin-top: 10px;
							letter-spacing: 2px;
							display: inline-block;
							//text-indent: -5px;
	    					line-height: 18px;
	    					position: absolute;
	    					z-index: 2;
	    					text-align: center;
	    					color: white;
						}

						&:hover{
							@media @768{

								a{
									color: @darkerGrey;
								}

								h2{
									color: @darkerGrey;
								}

								.arrow{
									color: @darkerGrey;
								}

								.bg{
									width: 100%;
								}
							}
						}
					}
				}
			}
		}
	}
	
	/*---------- AWARDS ----------*/
	.awards{
		height: 940px;
		.background{
			background-color: @darkGrey;

			.hex{
				// top: 20px;
				.at2x('../img/hex_red_bg.png', 842px, 978px);
				height: 300px;
				width: 260px;
				.cover;
				margin-top: 30px;

				@media @768{
					width: 490px;
    				// background-position: center;
    				background-repeat: no-repeat;
    				position: absolute;
   	 				left: -0px;
    				height: 100%;
					margin-top: 0px;
    				// top: 0;
				}

				@media @1366{
					width: 710px;
					left: -0px;
				}
			}

		}

		.content{
			// display: table;

			.innerContent{
				// display: table-cell;
				position: absolute;
				top: 50px;
				left: 0;
				right: 0;
				bottom: 0;
				margin: auto;
				//height: 129px;
				vertical-align: middle;

				@media @768{
					height: 300px;
					top: 0px;
				}
				
				.title{
					@media @768{
						float: left;
						margin-top: 90px;
					}
					
					h1{
						opacity: 1;
						margin-left: 20px;
						margin-top: 6px;
						width: 280px;

						@media @768{
							margin-left: 50px;
						}

						@media @1366{
							margin-left: 150px;
							width: 440px;
							font-size: 95px;
    						line-height: 78px;
						}
					}

					.rule{
						border-bottom: 1px solid white;
						margin-top: 5px;
						margin-bottom: 10px;
						width: 277px;

						@media @768{
							width:320px;
						}

						@media @1366{
							width: 404px;
						}
					}
				}
				
				.awards{
					width: 193px;
					margin: 0 auto;
					margin-top: 40px;

					@media @768{
			    		width: 510px;
					    position: absolute;
					    right: 0;
					    top: 0;
					    bottom: 0;
					    margin: auto;
					    height: 210px;
					}

					@media @1024{
						width: 710px;
						height: 270px;
					}

					@media @1366{
						margin-right: 150px;
						width: 625px;
					}
					
					h1{
						color:white;
						opacity: 1;
						font-size: 17px;
		    			letter-spacing: 2px;
		    			font-family: DINCondensed-Bold;
		    			text-transform: uppercase;
		    			text-align: center;
		    			width: auto;
		    			margin: 0 auto;
					}

					.awrd{
						margin: 10px;
						vertical-align: middle;

						@media @1024{
							margin-left: 40px;
						    margin-right: 40px;
						    margin-bottom: 20px;
						    margin-top: 20px;
						}
					}

					.awardOne{
						display: inline-block;
						margin-right: 43px;
						
						@media @1024{
							margin-right: 63px;
						}
						.img{
							.at2x('../img/canneslion_logo.png', 100px, 100px);
							.ccnr;
							height: 100px;
							width: 104px;
						}
					}

					.awardTwo{
						display: inline-block;
						.img{
							.at2x('../img/one_logo.png', 154px, 100px);
							.ccnr;
							height: 100px;
							width: 154px;
						}
					}

					.awardThree{
						display: inline-block;
						.img{
							.at2x('../img/mashies_logo.png', 100px, 100px);
							.ccnr;
							height: 100px;
							width: 100px;
						}
					}

					.awardFour{
						display: inline-block;
						.img{
							.at2x('../img/emmy_logo.png', 100px, 100px);
							.ccnr;
							height: 100px;
							width: 100px;
						}
					}

					.awardFive{
						display: inline-block;
						.img{
							.at2x('../img/thewebby_logo.png', 177px, 100px);
							.ccnr;
							height: 100px;
							width: 177px;
						}
					}

					.awardSix{
						display: inline-block;
						.img{
							.at2x('../img/fitc_logo.png', 100px, 100px);
							.ccnr;
							height: 100px;
							width: 100px;
						}
					}
				}
			}
		}
	}
	
	/*---------- CLIENTS ----------*/
	.clients{
		height: 1050px;

		.background{
			background-color: @darkGrey;
			overflow: hidden;

			.imposium{
				.at2x('../img/imposium_bg.png', 1108px, 1108px);
				height: 100%;
				width: 100%;
				.cover;
				opacity: 0.7;

				@media @768{
    				width: 590px;
    				background-position: center;
    				background-repeat: no-repeat;
    				position: absolute;
    				left: -90px;
				}

				@media @1366{
					width: 860px;
					left: -120px;
				}
			}
		}

		.content{
			.innerContent{
				.title{
					@media @768{
						float: left;
					}

					h1{
						opacity: 1;
						margin-left: 20px;
						width: 280px;
					    margin-top: 40px;

						@media @768{
							margin-left: 50px;
							margin-top: 40px;
							width: 216px;
						}

						@media @1366{
							margin-left: 150px;
							width: 350px;
							font-size: 95px;
    						line-height: 78px;
						}
					}

					.rule{
						border-bottom: 1px solid white;
						margin-top: 5px;
						margin-bottom: 10px;
						width: 200px;

						@media @768{
							width:260px;
						}

						@media @1366{
							width:480px;
						}
					}
				}

				.clients{
					width: 213px;
					margin: 0 auto;
					margin-top: 30px;


					@media @768{
		 				width: 555px;
   						position: absolute;
						right: 0;
						top: 0;
						bottom: 0;
						margin: auto;
						height: 300px;
					}

					@media @1024{
						width: 715px;
					}

					@media @1366{
						margin-right: 150px;
						width: 595px;
					}

					.logo{
						margin: 10px;

						@media @768{
						    vertical-align: middle;
						    width: 165px !important;
						}

						@media @1024{
							margin: 10px;
						    width: 175px !important;
						}
					}

					.tMobile{
						display: inline-block;
						.at2x('../img/tmobile_logo.png', 179px, 42px);
						.ccnr;
						height: 42px;
						width: 179px;
					}

					.activision{
						display: inline-block;
						.at2x('../img/activision_logo.png', 169px, 42px);
						.ccnr;
						height: 42px;
						width: 169px;
					}

					.cpb{
						display: inline-block;
						.at2x('../img/cpb_logo.png', 91px, 83px);
						.ccnr;
						height: 83px;
						width: 91px;
					}

					.tool{
						display: inline-block;
						.at2x('../img/tool_logo.png', 83px, 83px);
						.ccnr;
						height: 83px;
						width: 83px;

						
					}

					.google{
						display: inline-block;
						.at2x('../img/google_logo.png', 148px, 50px);
						.ccnr;
						height: 50px;
						width: 148px;
						margin-bottom: 20px;

						
					}

					.droga{
						display: inline-block;
						.at2x('../img/droga_logo.png', 110px, 60px);
						.ccnr;
						height: 60px;
						width: 110px;
		
						
					}

					.NFB{
						display: inline-block;
						.at2x('../img/logo_nfb.png', 140px, 68px);
						.ccnr;
						height: 68px;
						width: 140px;
		
						
					}

					.letgo{
						display: inline-block;
						.at2x('../img/logo_letgo.png', 125px, 75px);
						.ccnr;
						height: 75px;
						width: 125px;
						margin-bottom: 0px;

						// @media @1024{
						// 	margin-left: 60px;
						// }
					}

					.ford{
						display: inline-block;
						.at2x('../img/logo_ford.png', 165px, 66px);
						.ccnr;
						height: 66px;
						width: 165px;

						// @media @1024{
						// 	margin-left: 30px;
						// }
					}

				}
			}
		}
	}
}