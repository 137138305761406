@keyframes animateNav {
    0%  {
        transform: translateY(-50px);
        opacity: 0;
    }
    100% {
        transform: translateY(0px);
        opacity: 1;
    }
}


.header{
    -webkit-font-smoothing: antialiased;
    position: fixed;
    top:0px;
    left:0px;
    width:100%;
    height:60px; 
    z-index:10000;
    .headerBg{
        position: absolute;
        width: 100%;
        height: 60px;
        top: 0px;
        background-color: rgba(0,0,0,0.9);
        &.showBack{
            opacity: 1 !important;
            transition:opacity 800ms @easeInQuad;
        }
        &.hideBack{
             opacity: 0;
             transition:opacity 800ms @easeInQuad;
        }
    }

    .logo{
        .at2x('../img/imposium_logo.png', 132px, 35px);
        float: left;
        //margin-right: 40px;
        width: 132px;
        height: 35px;
        margin-top: 10px;
        margin-left: 15px;

        @media @768{
          margin-left: 50px;  
        }
    }
    a{
        position: absolute;
    }

    nav{
        float: right;
        display: none;
        
        @media @900{
            display: inline-block;
        }


        a{  
            color:white;
            text-transform: uppercase;
            font-size: 14px;
            letter-spacing:3px;
            font-family: DINCondensed-Bold;
            text-decoration: none;
            margin-right:30px;
            line-height: 60px;
            position: relative;
            overflow: hidden;
            opacity: 1;
    
            &.animateNavItem{
                opacity: 0;
                animation: animateNav 800ms @easeInQuad forwards;
                &.howitworksHeader{
                    animation-delay: 100ms;
                }
                &.showcaseHeader{
                    animation-delay: 200ms;
                }
                &.servicesHeader{
                    animation-delay: 300ms;
                }
                &.faqHeader{
                    animation-delay: 400ms;
                }

                &.newsHeader{
                    animation-delay: 500ms;
                }
                
                &.contactHeader{
                    animation-delay: 600ms;
                }
            }

            @media @768{
                display: inline-block;
            }

            @media @1024{
                margin-right: 50px;
            }
            .line{
                position: absolute;
                width: 0;
                height: 2px;
                top: 40px;
                transition:width 300ms @easeOutQuad;
            }

            .featuresHeader{
                background-color: #781594;
            }

            .platformHeader{
                background-color: #2d8ceb;
            }

            .howitworksHeader{
                background-color: @orange;
            }
            .showcaseHeader{
                background-color: @red;
            }
            .servicesHeader{
                background-color: @green;
            }
            .contactHeader{
                background-color: @yellow;
            }

            .newsHeader{
                background-color: @blue;
            }

            .faqHeader{
                background-color: white;
            }

            &:hover{
                .line{
                    width: 100%;
                }
            }
        }
      
    }
    .mobileMenuIcon{
        display: block;
        margin-top: 12px;
        margin-right: 25px;
        font-size: 22px;
        color: white;
        cursor: pointer;
        position: absolute;
        right: 0;
        @media @900{
            display: none;
        }
    }
}

.mo-banner{
    position: absolute;
    width: 100%;
    top: 60px;
    height: 58px;
    background-color: rgb(65, 75, 249);
    font-family: Montserrat, sans-serif;
    color: rgb(255, 255, 255);
    z-index: 10000;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    box-sizing: border-box;
    padding-left:20px;
    font-size:10px;

    @media only screen and (min-width: 800px){
        padding-left: 50px;
        font-size: 16px;
    }

    @media only screen and (min-width: 1550px){
        font-size: 22px;
    }

    .btn-pr{
        color:white;
        display: block;
        font-size: 9px;
        text-transform: uppercase;
        font-weight: 700;
        border-radius: 15px;
        height:30px; 
        box-sizing: border-box;
        padding-left: 5px;
        padding-right: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left:10px;
        margin-right:10px;
        white-space: nowrap;
        border: 1px solid white;
        text-decoration: none;

        @media only screen and (min-width: 768px){
            font-size: 16px;
            margin-left:50px;
            border: 2px solid white;
            padding-left: 15px;
            padding-right: 15px;    
            border-radius: 20px;
            height: 40px; 
        }
    }
}