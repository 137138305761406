.hiddenWindow{
	font-family: DINCondensed-Bold;
	-webkit-font-smoothing: antialiased;
	.top{
		.background{
			.at2x('../img/hiddenwindow_casestudy.jpg', 1920px, 600px);
			.cover;
			.ccnr;
			
			.overlay{
				.layer;
				background-color: rgba(0,0,0,0.4);
			}
		}

		.icon{
			.layer;
			font-size: 20px;
			color: white;
		}

		.content{
			display: table;
			.innerContent{
				display: table-cell;
				vertical-align: middle;
				
				h1{
					opacity: 0.6;
					font-size: 50px;
					line-height: 42px;	
					color: white;
					text-transform: uppercase;
					font-family: DINCondensed-Bold;
					width: 260px;
					margin-left: 15px;
					margin-top: 80px;

					@media @768{
						font-size: 90px;
						line-height: 72px;
						margin-left: 35px;
						width: 538px;
					}

				}

				.rule{
					width: 268px;
					border-bottom: 1px solid white;
    				margin-top: 0px;
					margin-bottom: 5px;

					@media @768{
						width: 490px;
					}

				}

				.cta{
					text-transform: uppercase;
					font-size: 17px;
					color: white;
					font-family: DINCondensed-Bold;
					display: inline-block;
					letter-spacing: 2px;
					margin-left: 15px;
					width: 260px;
					line-height: 20px;
    				margin-top: 10px;

					@media @768{
						margin-left: 35px;
					}
				}
			}
		}
	}


	.featuredLeft{
		height: 700px;
		color: white;
		.background{
			background-color: @darkerGrey;
		}

		.content{
			display: table;
			.innerContent{
				display: table-cell;
				vertical-align: middle;

				.wrap{
					margin: 0 auto;
					width: 240px;

					@media @768{
						width: 780px;
					}

					@media @960{
						width: 890px;
					}

					@media @1366{
						width:1350px;
					}


					.icon{
						.at2x('../img/laurels_logo.png', 160px, 130px);
	   					margin-bottom: 15px;
	   					opacity: 0.9;
	   					width: 160px;
	    				height: 130px;

	   					@media @768{
	   						float: left;
	    					margin-left: 100px;
	    					margin-bottom: 0px;
	    					margin-top: 40px;
	   					}

	   					@media @960{
	   						margin-top: 60px;
	   					}

	   					@media @1366{
	   						margin-left: 300px;
	   					}
					}

					.text{

						@media @768{
							position: relative;
	    					float: right;
	    					margin-left: 0px;
	    					margin-bottom: 0px;
	    					margin-right: 100px;
						}

						@media @1366{
							width: 520px;
							margin-right: 300px;
						}

						h1{
							font-family: DINCondensed-Bold;
							text-transform: uppercase;
						    font-size: 45px;
						    text-align: left;
						    letter-spacing: 3px;
							line-height: 38px;
			    			width: 260px;

			    			@media @768{
			    				line-height: 40px;
			    			}
						}

						p{
							width: 240px;
			   				.dinLight;
			   				font-size: 14px;

			   				@media @768{
			   					width: 340px;
			   				}

			   				@media @960{
			   					font-size: 17px;
			   					width: 450px;
			   				}

			   				@media @1366{
			   					width: 530px;
			   				}
						}
					}
				}
			}
		}
	}

	.featured{
		color: white;
		box-sizing:border-box;
		position: relative;
	    overflow: hidden;
		.background{
			position: absolute;
			top: 0;
			left: 0;
			height: 100%;
			width: 100%;
			background-color: @darkerGrey;
		}

		.content{
			display: table;
			position: relative;
			.innerContent{
				display: table-cell;
				vertical-align: middle;

				.text{
					margin: 0 auto;
					width: 240px;
					padding-top: 50px;
					padding-bottom: 50px;
					width: 100%;

					@media @768{
						padding-top: 80px;
						padding-bottom: 80px;
					}

					@media @1366{
						padding-top: 100px;
						padding-bottom: 100px;
					}

					h1{
						font-family: DINCondensed-Bold;
						text-transform: uppercase;
					    font-size: 35px;
					    text-align: left;
					    letter-spacing: 3px;
						line-height: 30px;
		    			padding-left: 50px;
						padding-right: 50px;


						@media @1024{
							padding-right: 200px;
							padding-left: 200px;
						}
					}

					p{
		   				.dinLight;
		   				font-size: 14px;
		   				padding-left: 50px;
						padding-right: 50px;

						@media @768{
							font-size: 18px;
						}

						@media @1024{
							padding-right: 200px;
							padding-left: 200px;
						}
					}
				}

				.icon{
					font-size: 100px;
   					margin-left: 15px;
   					margin-bottom: 15px;
   					line-height: 0px;
   					opacity: 0.8;

   					@media @768{
   						font-size: 190px;
    					position: relative;
    					float: right;
    					margin-left: 0px;
    					margin-bottom: 0px;
    					margin-right: 100px;
   					}
				}
			}
		}
	}
	

	.odd{
		position: relative;
		.background{
			position: absolute;
			top: 0px;
			left: 0px;
			.at2x('../img/hiddenwindow_casestudy_mid.jpg', 1920px, 600px);
			.cover;
			.ccnr;
			height: 100%;
			width: 100%;
			.overlay{
				.layer;
				background-color: rgba(0,0,0,0.6);
			}
		}
		.content{
			position: relative;
		}
	}

	.storeSection{
		background-color: @darkerGrey;

		color: white;
		position: relative;

		.storeBtns{
				padding-bottom: 50px;
				margin: 0 auto;
			.btnWrap{
				width: 100%;
    			text-align: center;

				.store{
					margin: 0 auto;
					margin: 10px;
				}

				.google{
					.at2x('../img/app_store.png', 200px, 74px);
					height: 74px;
					width: 200px;
					.cover;
					.ccnr;
					display: inline-block;
				}

				.apple{
					.at2x('../img/google_store.png', 228px, 74px);
					height:74px;
					width: 228px;
					.cover;
					.ccnr;
					display: inline-block;
				}
			}
		}
	}
}