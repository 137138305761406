/**
 * Global Reset of all HTML Elements

 */

html, body {
  border: 0;
  font-family: "Helvetica-Neue", "Helvetica", Arial, sans-serif;
  line-height: 1.5;
  margin: 0;
  padding: 0;
}

div, span, object, iframe, img, table, caption, thead, tbody,
tfoot, tr, tr, td, article, aside, canvas, details, figure, hgroup, menu,
nav, footer, header, section, summary, mark, audio, video {
  border: 0;
  margin: 0;
  padding: 0;
}

h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, address, cit, code,
del, dfn, em, ins, q, samp, small, strong, sub, sup, b, i, hr, dl, dt, dd,
ol, ul, li, fieldset, legend, label {
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
  margin: 0;
  padding: 0;
}

article, aside, canvas, figure, figure img, figcaption, hgroup,
footer, header, nav, section, audio, video {
  display: block;
}

table {
  border-collapse: separate;
  border-spacing: 0;
  caption, th, td {
    text-align: left;
    vertical-align: middle;
  }
}

a img {
  border: 0;
}

:focus {
  outline: 0;
}

/*************************************/
/* ------------ MIX IN'S ----------- */
/*************************************/

@blue: #3588BA; 
@lightGrey: #434343;
@middleGrey: #2E2E2E;
@darkGrey: #222222;
@darkerGrey: #131313;
@red: #C93030;
@yellow: #F3D804;
@pink: #EA0E8E;
@purple: #4F0763;
@orange: #F18A37;
@green: #A1B83A;
@pink: #EE018D;
@fordBlue: #006CCB;
@pressYellow: #FFD610;
@orstedBlue: #4099da;
@taGreen: #00a680;
@ihgOrange:#d96932;
@gcPink:#aa1d6b;
@remaxRed:#b2292e;
@havenLifeBlue:#07b7e0;
@marriottBlue:#109aee;
@shawBlue:#00a7f0;
@verizonRed:#cd040b;

.sans {
  font-family: Arial, helvetica, sans-serif;
}

.serif {
  font-family: Georgia, serif;
}

.layer {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
}

.smartFill {
  width: 100%;
  max-height: 100%;
}

@highdpi: ~"(-webkit-min-device-pixel-ratio: 1.5), (min--moz-device-pixel-ratio: 1.5), (-o-min-device-pixel-ratio: 3/2), (min-resolution: 1.5dppx)";

.at2x(@path, @w: auto, @h: auto) {
  // background-image: url(@path);
  @at2x_path: ~`@{path}.replace(/\.\w+$/, function(match) { return "@2x" + match; })`;

  // @media @highdpi {
  background-image: url("@{at2x_path}");
  background-size: @w @h;
  // }
}

.at2xForce(@path, @w: auto, @h: auto) {
  @at2x_path: ~`@{path}.replace(/\.\w+$/, function(match) { return "@2x" + match; })`;
  background-image: url("@{at2x_path}");
  background-size: @w @h;
}

.sprite(@w, @h, @x, @y) {
  background-image: url("../img/sprites@2x.png");
  background-repeat: no-repeat;
  background-size: @w @h;
  background-position: @x @y;
}

.ccnr {
  background-position: center center;
  background-repeat: no-repeat;
}

.cover {
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  -ms-behavior: url(../js/vendor/backgroundsize.min.htc);
}

.perspective(@val) {
  perspective: @val;
  -webkit-perspective: @val;
}

.rounded-corners(@radius: 5px) {
  -webkit-border-radius: @radius;
  -moz-border-radius: @radius;
  -ms-border-radius: @radius;
  -o-border-radius: @radius;
  border-radius: @radius;

  .ie8 & {
    -webkit-border-radius: 29px;
    -moz-border-radius: 29px;
    -ms-border-radius: 29px;
    -o-border-radius: 29px;
    border-radius: 29px;
    behavior: url(../js/vendor/PIE.htc);
  }
}

.border-box {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.size(@width, @height) {
  width: @width;
  height: @height;
}

.position(@position, @zindex) {
  position: @position;
  z-index: @zindex;
}

.triggerGPU {
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  -ms-transform: translateZ(0);
  -o-transform: translateZ(0);
  transform: translateZ(0);
}

.transformOrigin(@origin) {
  -webkit-transform-origin: @origin;
  -moz-transform-origin: @origin;
  -ms-transform-origin: @origin;
  -o-transform-origin: @origin;
  transform-origin: @origin;
}

.animation(@val) {
  animation: @val;
  -webkit-animation: @val;
  -moz-animation: @val;
  -o-animation: @val;
}

.transition(@type, @speed, @ease, @delay:0ms) {
  -webkit-transition: @type @speed @ease @delay;
  -moz-transition: @type @speed @ease @delay;
  -ms-transition: @type @speed @ease @delay;
  -o-transition: @type @speed @ease @delay;
  transition: @type @speed @ease @delay;
}

.translate3d(@x, @y, @z) {
  -webkit-transform: translate3d(@x, @y, @z);
  -moz-transform: translate3d(@x, @y, @z);
  -ms-transform: translate3d(@x, @y, @z);
  -o-transform: translate3d(@x, @y, @z);
  transform: translate3d(@x, @y, @z);
}

.translate(@x, @y) {
  -webkit-transform: translate(@x, @y);
  -moz-transform: translate(@x, @y);
  -ms-transform: translate(@x, @y);
  -o-transform: translate(@x, @y);
  transform: translate(@x, @y);
}

.scale(@x, @y) {
  -webkit-transform: scale(@x, @y);
  -moz-transform: scale(@x, @y);
  -ms-transform: scale(@x, @y);
  -o-transform: scale(@x, @y);
  transform: scale(@x, @y);
}

.transform(@val) {
  -webkit-transform: @val;
  -moz-transform: @val;
  -ms-transform: @val;
  -o-transform: @val;
  transform: @val;
}

.rotate(@val) {
  -webkit-transform: rotate(@val);
  -moz-transform: rotate(@val);
  -ms-transform: rotate(@val);
  -o-transform: rotate(@val);
  transform: rotate(@val);
}

.rotateX(@val) {
  -webkit-transform: rotateX(@val);
  -moz-transform: rotateX(@val);
  -ms-transform: rotateX(@val);
  -o-transform: rotateX(@val);
  transform: rotateX(@val);
}

.rotateY(@val) {
  -webkit-transform: rotateY(@val);
  -moz-transform: rotateY(@val);
  -ms-transform: rotateY(@val);
  -o-transform: rotateY(@val);
  transform: rotateY(@val);
}

.translateX(@val) {
  -webkit-transform: translateX(@val);
  -moz-transform: translateX(@val);
  -ms-transform: translateX(@val);
  -o-transform: translateX(@val);
  transform: translateX(@val);
}

.translateY(@val) {
  -webkit-transform: translateY(@val);
  -moz-transform: translateY(@val);
  -ms-transform: translateY(@val);
  -o-transform: translateY(@val);
  transform: translateY(@val);
}

.translateZ(@val) {
  -webkit-transform: translateZ(@val);
  -moz-transform: translateZ(@val);
  -ms-transform: translateZ(@val);
  -o-transform: translateZ(@val);
  transform: translateZ(@val);
}

.perspective(@val) {
  perspective: @val;
  -webkit-perspective: @val;
}

.prespectiveOrigin(@val) {
  perspective-origin: @val;
  -webkit-perspective-origin: @val;
}

.no-select {
  -webkit-user-select: none; /* Chrome/Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+ */

  /* Rules below not implemented in browsers yet */
  -o-user-select: none;
  user-select: none;
}


/*************************************/
/* ------------ TRANSISIONS ------------ */
/*************************************/

@easeOutCubic: cubic-bezier(.215, .61, .355, 1);
@easeInOutCubic: cubic-bezier(.645, .045, .355, 1);
@easeInCirc: cubic-bezier(.6, .04, .98, .335);
@easeOutCirc: cubic-bezier(.075, .82, .165, 1);
@easeInOutCirc: cubic-bezier(.785, .135, .15, .86);
@easeInExpo: cubic-bezier(.95, .05, .795, .035);
@easeOutExpo: cubic-bezier(.19, 1, .22, 1);
@easeInOutExpo: cubic-bezier(1, 0, 0, 1);
@easeInQuad: cubic-bezier(.55, .085, .68, .53);
@easeOutQuad: cubic-bezier(.25, .46, .45, .94);
@easeInOutQuad: cubic-bezier(.455, .03, .515, .955);
@easeInQuart: cubic-bezier(.895, .03, .685, .22);
@easeOutQuart: cubic-bezier(.165, .84, .44, 1);
@easeInOutQuart: cubic-bezier(.77, 0, .175, 1);
@easeInQuint: cubic-bezier(.755, .05, .855, .06);
@easeOutQuint: cubic-bezier(.23, 1, .32, 1);
@easeInOutQuint: cubic-bezier(.86, 0, .07, 1);
@easeInSine: cubic-bezier(.47, 0, .745, .715);
@easeOutSine: cubic-bezier(.39, .575, .565, 1);
@easeInOutSine: cubic-bezier(.445, .05, .55, .95);
@easeInBack: cubic-bezier(.6, -.28, .735, .045);
@easeOutBack: cubic-bezier(.175, .885, .32, 1.275);
@easeInOutBack: cubic-bezier(.68, -.55, .265, 1.55);

/*************************************/
/* ------------ MEDIA QUERIES ------------ */
/*************************************/

@375: ~"only screen and (min-width: 375px)";
@500: ~"only screen and (min-width: 500px)";
@768: ~"only screen and (min-width: 768px)";
@700h: ~"only screen and (max-height: 700px)";
@900: ~"only screen and (min-width: 900px)";
@960: ~"only screen and (min-width: 960px)";
@1024: ~"only screen and (min-width: 1024px)";
@1200: ~"only screen and (min-width: 1200px)";
@1366: ~"only screen and (min-width: 1366px)";
@1600: ~"only screen and (min-width: 1600px)";

