.shawbillexplainer{
    
    .top{
        .background{
            background:@shawBlue;
        }
    }

    .infoTwo{
        .background{
            background:@shawBlue;
        }
    }
    
    .infoThree{
        .background{
            background:@shawBlue;
        }
    }
    
    .stats{
        .statsContainer{
            .statsItem{
                .headline{
                    span{
                        color: @shawBlue;
                    }
                }
            }
        }
    }

    .press{
        .pressContainer{
            .pressItem{
                .title{
                    h2{
                        color: @shawBlue;
                    }
                }
            }   
        }
    }

    // .media{
    //     .mediaContainer{
    //         .imageWrapper{
    //             display: block;
                
    //             .thumbnail{
    //                 display: inline-block;
    //                 width:33.3%;
    //                 .bg{
    //                     width:33.3%;
    //                     background-size: cover;
    //                     background-position: top center;
    //                 }

    //                 .overlay{
    //                     width:33.3%;
    //                 }
    //             }                

    //             .first{
    //                 .bg{
    //                     background-image:url('../img/tostitos_thumbnail_dan.jpg');
    //                 }
    //             }

    //             .third{
    //                 .bg{
    //                     background-image:url('../img/tostitos_thumbnail_will.jpg');
    //                 }
    //             }

    //             .second{
    //                 .bg{
    //                     background-image:url('../img/tostitos_thumbnail_richard.jpg');
    //                 }
    //             }

    //             .fourth{
    //                 display: none;
    //             }
    //         }   
    //     }
    // }
}