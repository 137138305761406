.letGo{
	
	.top{
		.background{
			background-image:url('../img/hero_letgo.jpg');
		}
	}

	.infoTwo{
		.background{
			background-image:url('../img/letgo_casestudy_hero.jpg');
		}
	}
	
	.infoThree{
		.background{
			background-image:url('../img/letgo_casestudy_hero_two.jpg');
		}
		.content{
			.innerContent{
				.text{
					p{
					    font-size: 12px;
				        line-height: 15px;

					    @media @768{
					        font-size: 16px;
					        line-height: 18px;
					        padding-left: 50px;
					        padding-right: 50px;
					    }

					    @media @1024{
					        font-size: 18px;
					        line-height: 22px;
					    }

					    @media @1366{
					        padding-left:200px;
					        padding-right:200px;
					    }
					}
				}
			}
		}
	}

	.infoFour{
		background-image:url('../img/letgo_casestudy_hero_three.jpg');

		.content{
			.innerContent{
				.text{
					p{
					    font-size: 12px;
				        line-height: 13px;

					    @media @768{
					        font-size: 16px;
					        line-height: 18px;
					        padding-left: 50px;
					        padding-right: 50px;
					    }

					    @media @1024{
					        font-size: 18px;
					        line-height: 22px;
					    }

					    @media @1366{
					        padding-left:200px;
					        padding-right:200px;
					    }
					}
				}
			}
		}
	}

	.media{
		.mediaContainer{
			.imageWrapper{
				display: block;
				.first{
					display: none;
					.bg{
						.at2x('../img/action_thumbnail_casestudy.jpg', 333px, 200px);
					}
				}

				.second{
					display: inline-block;
					.bg{
						.at2x('../img/perfume_thumbnail_casestudy.jpg', 333px, 200px);
					}
				}

				.third{
					display: inline-block;
					.bg{
						.at2x('../img/pharma_thumbnail_casestudy.jpg', 333px, 200px);
					}
				}

				.fourth{
					display: inline-block;
					.bg{
						.at2x('../img/HS_thumbnail_casestudy.jpg', 333px, 200px);
					}
				}
			}	
		}
	}
}