
.aboutus{
	-webkit-font-smoothing: antialiased;
	font-family: DINCondensed-Bold;

	.hero{
		.hex{
			position: absolute;
			.at2x('../img/hex.png', 842px, 978px);
			.cover;
			height: 100%;
    		width: 100%;

			@media @768{
				width: 480px;
			}

			@media @1366{
				height: 100%;
				width: 580px;
			}

			@media @1600{
				height: 100%;
				width: 40%; 
			}
		}
		.content{
			display: table;
			.innerContent{
				display: table-cell;
				vertical-align: middle;
				
				h1{
					//opacity: 0.6;	
					font-size: 55px;
					color: white;
					line-height: 48px;
					text-transform: uppercase;
					font-family: DINCondensed-Bold;
					width: 260px;

				}
			}
		}
	}

	.top{
		height: 370px;
		.background{
			background-color: @darkGrey;
			.cover;
			.ccnr;
			
			.overlay{
				//.layer;
				//background-color: rgba(0,0,0,0.5);
				position: absolute;
				.cover;
				height: 100%;
    			width: 100%;
			}


		}
		
		.content{
			display: table;
			.innerContent{
				display: table-cell;
				vertical-align: middle;
				color: white;
				h1{
					margin-top: 50px;
	    			margin-left: 35px;
	    			width: 300px;
	    			font-family: DINCondensed-Bold;
					text-transform: uppercase;

	    			@media @500{
	    				//margin-top: 85px;
	    			}

	    			@media @768{
	    				margin-left: 50px;
	    			}

	    			@media @1024{
	    				font-size: 90px;
	    				line-height: 72px;
	    				width: 430px;
	    			}

					@media @1366{
						width:540px;
						font-size: 120px;
    					line-height: 92px;
    					margin-top: 85px;
					}
				}

				.rule{
					width: 254px;
					border-bottom: 1px solid white;
    				margin-top: 0px;
					margin-bottom: 10px;

					@media @1024{
						width:404px;
					}

					@media @1366{
						width: 528px;
   						margin-bottom: 10px;
    					margin-top: 5px;
					}
				}

				p{
					width: 280px;
	   				margin-left: 35px;
	   				.dinLight;
	   				font-size: 14px;

	   				@media @768{
	   					width: 570px;
    					margin-left: 50px;
	   				}

	   				@media @1024{
	   					font-size: 17px;
	   					width:876px;
	   					margin-left: 50px;
	   				}
				}			
			}
		}
	}

	.text{
		.background{
			background-color:@blue;

		}

		.content{
			color: white;
			display: table;
			font-family: DINCondensed-Bold;

			.innerContent{
				display: table-cell;
				vertical-align: middle;
				h1{
					font-family: DINCondensed-Bold;
				    text-transform: uppercase;
				    font-size: 35px;
				    text-align: left;
				    letter-spacing: 3px;
				    margin-left: 35px;
				    line-height: 31px;
				    width: 190px;

	    			@media @768{
	    				line-height: 26px;
					    width: 150px;
					    font-size: 25px;
	    			}

				    @media @1024{
				    	font-size: 41px;
					    line-height: 40px;
					    width: 220px;

				    }

				}

				.rule{
					border-bottom: 1px solid white;
					margin-top: 5px;
					margin-bottom: 10px;
					width: 178px;

					@media @768{
						width: 150px;
					}

					@media @1024{
						width: 198px;
					}
				}

				p{
					width: 200px;
	   				margin-left: 35px;
	   				.dinLight;
	   				font-size: 14px;


	   				@media @1024{
	   					font-size: 17px;
	   					width:280px;
	   				}

	   				@media @1366{
						width: 361px;
	   				}
				}

				.ctaContainer{

					.cta{
						cursor: pointer;
						text-transform: uppercase;
						font-size: 17px;
						margin-left: 35px;
						letter-spacing: 2px;
						display: inline-block;

						@media @1024{
							font-size: 20px;
						}

						a{
							text-decoration: none;
							color: white;
						}
					
					}

					.arrow{
						color: white;
		    			font-size: 15px;
		    			display: inline-block;
		    			margin-left: 5px;
					}

					&:hover{
						.arrow{
							.fa{
								animation: arrowHover 0.55s @easeInOutQuad 0.2s 1 forwards;
							}
						}
					}

				}

			}
		}
	}

	.about{
		overflow: hidden;

		.projectOverlay{	
			display: table;
			background-color: rgba(0,0,0,0.3);
			.layer;
			color: white;
			cursor: pointer;
			font-family: DINCondensed-Bold;

			
			.overlayContent{
				display: table-cell;
				vertical-align: bottom;
				padding-bottom: 30px;
				
				h1{
					text-transform: uppercase;
				    font-size: 28px;
	    			line-height: 37px;
				    text-align: left;
				    letter-spacing: 3px;
					line-height: 28px;
	    			width: 260px;
	    			margin-left: 35px;

	    			@media @768{
	    				line-height: 30px;
						width: 197px;
	    			}

				    @media @1024{
	    				line-height: 44px;
	    				width: 260px;

				    }

				}

				.rule{
					width: 150px;
    				border-bottom: 1px solid #fff;
    				margin-bottom: 10px;
				}

				h2{
					text-transform: uppercase;
					font-size: 17px;
					display: inline-block;
					letter-spacing: 2px;
					margin-left: 35px;
				}
			}

			@media @768{
				display: none;
			}
		}

		.content{
			overflow: hidden;
			display: table;

			@media @768{

				&:hover{
					.rollover{
						left: 0px !important;
						opacity:0.8;


						.innerRoll{
							opacity: 1;
							transition:opacity 800ms @easeInQuad;
							.rule{
								width: 200px;
								transition:width 700ms @easeInQuad;
							}
						}
					}
				}

			}
			
			.rollover{
				display: table;
				font-family: DINCondensed-Bold;
				background-color: @blue;
				width: 100%;
				height: 100%;
				position: absolute;
				color: white;
				// display: none;
				z-index: 100;
				transition:left 600ms @easeOutQuad;
				@media @500{
					// display: none;
					opacity: 0.6;
				}
				.innerRoll{
					position: absolute;
					margin: auto;
					top: 0;
					bottom: 0;
					opacity: 0;
					height: 170px;

					@media @768{
						height: 220px;
					}

					@media @1024{
						height: 190px;
					}


					h1{
						text-transform: uppercase;
					    font-size: 22px;
					    text-align: left;
					    letter-spacing: 3px;
					    margin-left: 15px;
		    			width: 260px;
		    			line-height: 1;

					    @media @1024{
					    	font-size: 28px;
		    				line-height: 24px;
		    				width: 260px;
		    				margin-left: 35px;

					    }

					}

					.rule{
						border-bottom: 1px solid white;
						margin-bottom: 5px;
						width: 0px;

						@media @1024{
							margin-top: 5px;
							margin-bottom: 10px;
						}

						@media @1366{
							margin-bottom: 15px;
						}
					}

					.title{
						text-transform: uppercase;
					    font-size: 14px;
					    text-align: left;
					    letter-spacing: 3px;
		    			width: 260px;
		    			margin-left: 15px;

		    			@media @1024{
		    				font-size: 18px;
		    				line-height: 21px;
    						width: 217px;
    						margin-left: 35px;
	
		    			}
					}

					p{
						width: 230px;
		   				margin-left: 15px;
		   				.dinLight;
		   				font-size: 14px;

		   				@media @1024{
		   					width:270px;
		   					margin-left: 35px;
		   				}

		   				@media @1366{
		   					font-size: 17px;
							width: 420px;
		   				}
					}

					h2{
						text-transform: uppercase;
						font-size: 17px;
						margin-left: 35px;
						margin-top: 10px;
						letter-spacing: 2px;
						display: inline-block;
						cursor: pointer;

						@media @1024{
							font-size: 20px;
						}

						a{
							text-decoration: none;
							color: white;
						}
					
					}

					.arrow{
						color: white;
						font-size: 15px;
						display: inline-block;
						margin-left: 5px;
					}

				}
				&:hover{
					.arrow{
						.fa{
							animation: arrowHover 0.55s @easeInOutQuad 0.1s 1 forwards;
						}
					}
				}
			}
		}
	}

	.jason{
		.background{
			.at2x('../img/jason.jpg', 500px, 500px);
			.cover;
			background-position: top center;

			.overlay{
				.layer;
				background-color: rgba(0,0,0,0.4);
			}
		}
	}

	.kailey{
		.background{
			.at2x('../img/kailey.jpg', 500px, 500px);
			.cover;
			background-position: top center;

			.overlay{
				.layer;
				background-color: rgba(0,0,0,0.5);
			}
		}
	}

	.greg{
		.background{
			.at2x('../img/greg.jpg', 500px, 500px);
			.cover;
			background-position: top center;

			.overlay{
				.layer;
				background-color: rgba(0,0,0,0.4);
			}
		}
	}

	.john{
		.background{
			.at2x('../img/john.jpg', 500px, 500px);
			.cover;
			background-position: top center;

			.overlay{
				.layer;
				background-color: rgba(0,0,0,0.4);
			}
		}
	} 

	.jeff{
		.background{
			.at2x('../img/jeff.jpg', 500px, 500px);
			.cover;
			background-position: top center;

			.overlay{
				.layer;
				background-color: rgba(0,0,0,0.4);
			}
		}
	}  

	.josh{
		.background{
			.at2x('../img/josh.jpg', 500px, 500px);
			.cover;
			background-position: top center;

			.overlay{
				.layer;
				background-color: rgba(0,0,0,0.2);
			}
		}
	}

	.zack{
		.background{
			.at2x('../img/zack.jpg', 500px, 500px);
			.cover;
			background-position: top center;

			.overlay{
				.layer;
				background-color: rgba(0,0,0,0.3);
			}
		}
	}

	.logan{
		.background{
			.at2x('../img/logan.jpg', 500px, 500px);
			.cover;
			background-position: top center;

			.overlay{
				.layer;
				background-color: rgba(0,0,0,0.3);
			}
		}
	}
	

	.bottom{
		height: 250px;
		color: white;
		.background{
			background-color: @darkGrey;
		}

		.content{
			display: table;
			.innerContent{
				display: table-cell;
				vertical-align: middle;

				.logoWrap{
					width: 200px;
   				 	margin: 0 auto;
    				margin-bottom: 10px;

    				@media @500{
						width: 380px;
					}

    				@media @768{
    					width: 250px;
    				}

    				@media @1024{
						width:270px;
						margin-bottom: 20px;
					}

					@media @1366{
						width: 400px;
					}

    				.logo{
						
					}
				}

				.title{
					text-transform: uppercase;
				    font-size: 28px;
	    			line-height: 27px;
				    letter-spacing: 3px;
					line-height: 38px;
					margin-bottom: 20px;
					width: 200px;
					//margin: 0 auto;

					@media @500{
						width: 380px;
					}

					@media @768{
    					width: 250px;
    				}

					@media @1024{
						width:270px;
					}

					@media @1366{
						width: 400px;
						font-size: 55px;
					}
				}

				p{
					width: 200px;
	   				margin-left: 35px;
	   				.dinLight;
	   				font-size: 14px;
	   				margin: 0 auto;

	   				@media @500{
						width: 380px;
					}

	   				@media @768{
    					width: 250px;
    				}

	   				@media @1024{
	   					width: 280px;
	   					font-size: 17px;
	   				}

	   				@media @1366{
						width: 400px;
	   				}
				}

				.ctaContainer{
					width: 200px;
					margin: 0 auto;
					margin-top: 10px;

					@media @500{
						width: 380px;
					}

					@media @768{
    					width: 250px;
    				}

					@media @1024{
						width:280px; 
					}

					@media @1366{
						width: 400px;
					}

					.cta{
						text-transform: uppercase;
						font-size: 17px;
						color: white;
						font-family: DINCondensed-Bold;
						display: inline-block;
						letter-spacing: 2px;

						a{
							text-decoration: none;
							color: white;
						}
					}

					.arrow{
						color: white;
		    			font-size: 15px;
		    			display: inline-block;
		    			margin-left: 5px;
				
					}
					
					&:hover{
						.arrow{
							.fa{
								animation: arrowHover 0.55s @easeInOutQuad 0.1s 1 forwards;
							}
						}
					}
				}
			}
		}
	}

	.nickel{
		.background{
			//background-color: @darkerGrey;
			overflow: hidden;
		}
		.content{
			.innerContent{		
				.logo{
					.at2x('../img/nickel_logo_small.png', 83px, 83px);
					.ccnr;
					height: 83px;
					width: 83px;
					//margin: 0 auto;
					//margin-bottom: 20px;
				}

				.title{
					text-transform: uppercase;
				    font-size: 28px;
	    			line-height: 27px;
				    letter-spacing: 3px;
					line-height: 28px;
					margin-bottom: 20px;
					width: 200px;
					margin: 0 auto;

					@media @500{
						width: 380px;
					}

					@media @768{
    					width: 250px;
    				}

					@media @1024{
						width:280px; 
					}

					@media @1366{
						width: 400px;
						font-size: 35px;
						line-height: 48px;

					}
				}
			}
		}
	}

	.tool{
		.background{
			//background-color: @darkerGrey;
			overflow: hidden;
		}

		.content{
			.innerContent{		
				.logo{
					.at2x('../img/tool_logo.png', 83px, 83px);
					.ccnr;
					height: 83px;
					width: 83px;
					//margin: 0 auto;
					//margin-bottom: 20px;
				}

				.title{
					text-transform: uppercase;
				    font-size: 28px;
	    			line-height: 27px;
				    letter-spacing: 3px;
					line-height: 28px;
					margin-bottom: 20px;
					width: 200px;
					margin: 0 auto;

					@media @500{
						width: 380px;
					}

					@media @768{
    					width: 250px;
    				}

					@media @1024{
						width:280px; 
					}

					@media @1366{
						width: 400px;
						font-size: 35px;
						line-height: 48px;
					}
				}
			}
		}
	}

	.partners{
		.background{
			//background-color: @darkerGrey;
			overflow: hidden;
		}

		.content{
			.innerContent{
			.title{
					text-transform: uppercase;
	    			line-height: 27px;
				    letter-spacing: 3px;
					line-height: 3px;
					margin-bottom: 20px;
					font-size: 55px;
    				//width: 280px;
    				margin-left: 35px;
    				//text-align: center;

    				@media @1024{
    					font-size: 68px;
    					line-height: 24px;
    					margin-left: 50px;
    				}
				}

				.rule{
					border-bottom: 1px solid white;
					width: 230px;

					@media @1024{
						width: 275px;
					}
				}	
			}
		}
	}

}