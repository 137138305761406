.videoOverlay{
    position: fixed;
    width: 100%;
    height: 100%;
    top: 60px;
    left: 0;
    z-index: 1222;
    pointer-events: none;
    &.open{
        height: 100%;
    }
    .contents{
        width: 100%;
        height: 0%;
        position: absolute;
        left: 0px;
        bottom: 0px;
        overflow: hidden;
        transition:height 800ms @easeOutQuint;
        pointer-events: auto;
         &.open{
             height: 100%;
         }
        .bg{    
            position: absolute;
            width:100%;
            height: 100%;
            background-color: black;
            opacity: 0.8;
            top: 0;
            left: 0;
            pointer-events: all;
        }
        .closeBtn{
            position: absolute;
            top: 10px;
            right: 30px;
            width: 30px;
            height: 40px;
            font-size: 30px;
            color: white;
            z-index: 2222;
            cursor: pointer;
            transform: rotate(0deg);
            transition: transform 0.3s @easeOutQuad;
            &:hover{
                transform: rotate(90deg);
            }
        }
        .videoHolder{
            position: absolute;
            width: 100%;
            height: 100%;
            #ytplayer{
                margin:auto;
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                width: 300px;
                height: 169px;
                opacity:0;
                transition:opacity 700ms @easeOutQuad;
                &.show{
                    opacity:1;
                }

                @media @768{
                    width: 720px;
                    height: 405px;
                }
            }
        }
    }
}