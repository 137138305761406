.faq{
	font-family: DINCondensed-Bold;
	-webkit-font-smoothing: antialiased;
	.top{
		.background{
			.at2x('../img/seances_hero_casestudy.jpg', 1920px, 1080px);
			.cover;
			.ccnr;
			
			.overlay{
				.layer;
				background-color: rgba(0,0,0,0.5);
			}
		}

		.icon{
			.layer;
			font-size: 20px;
			color: white;
		}

		.content{
			display: table;
			.innerContent{
				display: table-cell;
				vertical-align: middle;
				
				h1{
					opacity: 0.6;
					font-size: 50px;
					line-height: 42px;	
					color: white;
					text-transform: uppercase;
					font-family: DINCondensed-Bold;
					width: 260px;
					margin-left: 15px;
					margin-top: 80px;

					@media @768{
						font-size: 90px;
						line-height: 72px;
						margin-left: 35px;
						width: 538px;
					}

				}

				.rule{
					width: 188px;
					border-bottom: 1px solid white;
    				margin-top: 0px;
					margin-bottom: 5px;

					@media @768{
						width: 540px;
					}

				}

				.cta{
					text-transform: uppercase;
					font-size: 17px;
					color: white;
					font-family: DINCondensed-Bold;
					display: inline-block;
					letter-spacing: 2px;
					margin-left: 15px;
					width: 260px;
					line-height: 20px;
    				margin-top: 10px;

					@media @768{
						margin-left: 35px;
					}
				}
			}
		}
	}

	.question{
		color: white;
		 box-sizing:border-box;

		.content{
			display: table;
			.innerContent{
				display: table-cell;
				vertical-align: middle;

				.text{
					margin: 0 auto;
					width: 240px;
					padding-top: 50px;
					padding-bottom: 50px;
					width: 100%;

					@media @768{
						padding-top: 80px;
						padding-bottom: 80px;
					}

					@media @1366{
						padding-top: 100px;
						padding-bottom: 100px;
					}

					h1{
						font-family: DINCondensed-Bold;
						text-transform: uppercase;
					    font-size: 35px;
					    text-align: left;
					    letter-spacing: 3px;
						line-height: 30px;
		    			padding-left: 50px;
						padding-right: 50px;

						@media @1366{
							padding-right: 200px;
							padding-left: 200px;
						}
					}

					p{
		   				.dinLight;
		   				font-size: 14px;
		   				padding-left: 50px;
						padding-right: 50px;

						@media @768{
							font-size: 18px;
						}

						@media @1366{
							padding-right: 200px;
							padding-left: 200px;
						}

						a{
							color:white;
							text-decoration: underline;
						}
					}
				}

				.icon{
					font-size: 100px;
   					margin-left: 15px;
   					margin-bottom: 15px;
   					line-height: 0px;
   					opacity: 0.8;

   					@media @768{
   						font-size: 190px;
    					position: relative;
    					float: right;
    					margin-left: 0px;
    					margin-bottom: 0px;
    					margin-right: 100px;
   					}
				}
			}
		}
	}

	.even{
		.background{
			background-color: @darkGrey;
		}
	}

	.odd{
		.background{
			background-color: @darkerGrey;
		}
	}
}