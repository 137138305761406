.footer{
	-webkit-font-smoothing: antialiased;
	color: white;
	font-family: DINCondensed-Bold;
	text-transform: uppercase;

	.background{
		background-color: black;
	}

	.logo{
		background-color: black;
		height: 150px;
		.content{
			display: table;
			margin-left: 50px;

			@media @768{
				margin-left: 0px;
			}

			.innerContent{
				display: table-cell;
				vertical-align: middle;


				@media @768{
			   	    width: 210px;
					position: absolute;
				    top: 0;
				    bottom: 0;
				    right: 0;
				    left: 0;
				    height: 57px;
				    margin: auto;
				    display: inline-block;
			   	}

				.logo{
					.at2x('../img/imposium_logo.png', 132px, 35px);
			        width: 132px;
			        height: 35px;

			        @media @960{
			        	.at2x('../img/footer_logo.png', 212px, 57px);
				        width: 212px;
				        height: 57px;
			        }
				}	
			}
		}
	}



	.nav{
		background-color: black;
		height: 150px;
		margin: 0 auto;
		.content{
			margin-left: 50px;
			display: table;

			@media @768{
				margin-left: 0px;
			}
			.innerContent{
				display: table-cell;
				vertical-align: middle;

			  @media @768{
			   	    width: 90px;
					position: absolute;
				    top: 0;
				    bottom: 0;
				    right: 0;
				    left: 0;
				    height: 120px;
				    margin: auto;
				    display: inline-block;
			   	}
				
				.links{
					font-size: 14px;
					letter-spacing: 2px;

					a{
						text-decoration: none;
						cursor: pointer;
						color: white;
						transition:color 300ms @easeInQuad;

						&:hover{
							color: @green;
						}
					}
				}
			}	
		}
	}
					
	.social{
		background-color: black;
		height: 150px;
		.content{
			display: table;
			margin-left: 50px;

			@media @768{
				margin-left: 0px;
			}
			
			.innerContent{
				display: table-cell;
				vertical-align: middle;

				  @media @768{
				   	    width: 180px;
						position: absolute;
					    top: 0;
					    bottom: 0;
					    right: 0;
					    left: 0;
					    height: 50px;
					    margin: auto;
					    display: inline-block;
				   	}
				
				.icon{
					cursor: pointer;
					font-size: 30px;
					color: black;
					position: relative;
					line-height: 0;
					background-color: white;
					//border: 2px solid white;
					border-radius: 100px;
					text-decoration: none;
					transition:background-color 300ms @easeInQuad;

					&:hover{
						background-color: @yellow;
						//border: 2px solid @yellow;
						//color: black;
					}

					a{
						text-decoration: none;
						color: black;
					}
				}

				.youtubeFooter{
					display: inline-block;
					padding: 10px 12px;
					cursor: pointer;
					font-size: 30px;
					color: black;
					position: relative;
					margin-right: 10px;
					line-height: 0;
					background-color: white;
					//border: 2px solid white;
					border-radius: 100px;
					transition:background-color 300ms @easeInQuad;

					&:hover{
						background-color: #c4302b;
						//border: 2px solid @yellow;
						//color: black;
					}
				}

				.twitter{
					display: inline-block;
					padding: 10px 12px;
					cursor: pointer;
					font-size: 30px;
					color: black;
					position: relative;
					margin-right: 10px;
					line-height: 0;
					background-color: white;
					//border: 2px solid white;
					border-radius: 100px;
					transition:background-color 300ms @easeInQuad;

					&:hover{
						background-color: #33CCFF;
						//border: 2px solid @yellow;
						//color: black;
					}
				}

				.facebook{
					display: inline-block;
					padding: 10px 17px;
					cursor: pointer;
					font-size: 30px;
					color: black;
					position: relative;
					line-height: 0;
					background-color: white;
					//border: 2px solid white;
					border-radius: 100px;
					transition:background-color 300ms @easeInQuad;

					&:hover{
						background-color: #3B5998;
						//border: 2px solid @yellow;
						//color: black;
					}
				}
			}
		}

	}

	.contact{
		background-color: black;
		height: 150px;
		.content{
			display: table;
			margin-left: 50px;

			@media @768{
				margin-left: 0px;
			}
			.innerContent{
				display: table-cell;
				vertical-align: middle;

				@media @768{
			   	    width: 130px;
					position: absolute;
				    top: 0;
				    bottom: 0;
				    right: 0;
				    left: 0;
				    height: 60px;
				    margin: auto;
				    display: inline-block;
			   	}

				.headline{
					font-size: 19px;
    				width: 220px;
				    line-height: 20px;
				    margin-bottom: 5px;
				    letter-spacing: 2px;
				    text-transform: uppercase;
				}

				.info{
					font-size: 14px;
					letter-spacing: 2px;

					a{
						text-decoration: none;
						cursor: pointer;
						color: white;
						transition:color 300ms @easeInQuad;

						&:hover{
							color: @green;
						}
					}					
				}
			}
		}
	}
	   
}