
.blog{
	font-family: DINCondensed-Bold;
	-webkit-font-smoothing: antialiased;
	.top{
		.background{
			 //.at2x('../img/rotoscoping_hero_feature.jpg', 1920px, 973px);
			.cover;
			.ccnr;
			.overlay{
				.layer;
				background-color: @darkGrey;
				opacity: 0.8;
			}
		}

		.content{
			display: table;
			.innerContent{
				display: table-cell;
				vertical-align: middle;
				
				h1{
					opacity: 0.6;	
					font-size: 50px;
					color: white;
					line-height: 42px;
					text-transform: uppercase;
					font-family: DINCondensed-Bold;
					width: 300px;
					margin-left: 15px;
					margin-top: 40px;

					@media @768{
						font-size: 69px;
					    line-height: 53px;
					    margin-top: 70px;
					    width: 400px;
					    margin-left: 35px;
					}

					@media @960{
						font-size: 90px;
   						line-height: 68px;
					    margin-top: 70px;
					    width: 520px;
					}

					@media @1366{
						font-size: 125px;
    					line-height: 97px;
					    width: 683px;
					}
				}

				.rule{
					width: 265px;
					border-bottom: 1px solid white;
    				margin-top: 0px;
					margin-bottom: 5px;

					@media @768{
						width: 250px;
					}

					@media @960{
						width: 310px;
					}

					@media @1366{
						width: 650px;
					}
				}
			}
		}
	}

	.featured{
		color: white;
		.content{
			display: table;
			.innerContent{
				display: table-cell;
				vertical-align: middle;
				
				.text{
					margin-left: 15px;

					@media @768{
						margin-left:50px;
						margin-top: 20px;
					}

					h1{
						font-family: DINCondensed-Bold;
						text-transform: uppercase;
					    font-size: 30px;
		    			line-height: 37px;
					    text-align: left;
					    letter-spacing: 3px;
						line-height: 38px;
		    			width: 80%;
   						margin-top: 5px;
					}

					p{
						width: 90%;
		   				.dinLight;
		   				font-size: 14px;
		   				margin-right: 10px;

		   				@media @768{
		   					//width: 395px;
		   				}

		   				@media @960{
		   					font-size: 17px;
		   					//width:505px;
		   				}

		   				@media @1366{
		   					//width: 600px;
		   				}

					}

					.ctaBtn{
						cursor: pointer;
						width: 114px;
	    				height: 33px;
	   					border: 1px solid #fff;
	    				margin-top: 10px;
	    				position: relative;
	    				transition:color 300ms @easeInQuad;

	    				.bg{
	    					bottom: 0;
	    					width: 0;
	    					height: 100%;
	    					background-color: white;
	    					z-index: 1;
	    					position: absolute;
	    					transition:width 300ms @easeInQuad;
	    				}

						.cta{
							text-transform: uppercase;
							font-size: 15px;
							left: 0;
							right: 0;
							margin-top: 10px;
							letter-spacing: 2px;
							display: inline-block;
							text-indent: 2px;
	    					line-height: 18px;
	    					position: absolute;
	    					z-index: 2;
	    					text-align: center;
						}

						.arrow{
							color: white;
		    				font-size: 15px;
		    				display: inline-block;
		    				position: absolute;
		    				margin-left: 89px;
	    					line-height: 39px;
		    				z-index: 3;
		    				transition:color 300ms @easeInQuad;

						}

						&:hover{
							color: @darkerGrey;

							.arrow{
								color: @darkerGrey;
							}

							.bg{
								width: 100%;
							}
						}
					}

				}

				span{
					font-size: 50px;
   					margin-left: 15px;
   					margin-bottom: 15px;
   					line-height: normal;
   					opacity: 0.8;

   					@media @768{
   						font-size: 190px;
    					position: relative;
    					float: right;
    					margin-left: 0px;
    					margin-bottom: 0px;
    					margin-right: 100px;
   					}

				}
			}
		}
	}

	.gridFeature{
		.content{
			.innerContent{			
				.featuredIcon{
					position: relative;
					.ccnr;
					height: 70px;
		    		width: 70px;
		    		bottom: 10px;
		    		margin-left: 15px;
		    		
		    		@media @768{
						height: 120px;
					    width: 120px;
					    margin-left: 0;
					    margin-bottom: 0;
					    position: absolute;
					    right: 20px;
					    top: 0;
					    bottom: 0;
					    height: 100%;
		    		}

		    		@media @1200{
						right: 80px;
		    		}
				}
			}
		}
	}

	.even{
		.background{
			//.at2x('../img/social_hero_feature.jpg', 1920px, 973px);
			.cover;
			.ccnr;
			.overlay{
				.layer;
				background-color: @darkGrey;
				opacity: 0.8;
			}
		}
	}

	.odd{
		.background{
			 //.at2x('../img/rotoscoping_hero_feature.jpg', 1920px, 973px);
			.cover;
			.ccnr;
			.overlay{
				.layer;
				background-color: @darkerGrey;
				opacity: 0.8;
			}
		}
	}
}