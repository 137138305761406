.lollipop{

	.top{
		.background{
			background-image:url('../img/lollipop_top_bg@2x.jpg');
		}
	}

	.infoTwo{
		.background{
			background-image:url('../img/lollipop_header_bg@2x.jpg');
		}
	}

	.infoThree{
		.background{
			background-image:url('../img/lollipop_mid_bg@2x.jpg');
		}
	}		

	.stats{
		.statsContainer{
			.statsItem{
				.headline{
					span{
						color: @red;
					}
				}
			}
		}
	}

	.press{
		.pressContainer{
			.pressItem{
				.title{
					h2{
						color: @red;
					}
				}
			}	
		}
	}
}