.tMobile{

	.top{
		.background{
			background-image:url('../img/tmobile_mid_casestudy@2x.jpg');
		}
	}

	.infoTwo{
		.background{
			background-image:url('../img/tmobile_bg.jpg');
		}
	}

	.infoThree{
		position: relative;
		overflow: hidden;
		.background{
			background-color: #BA0060;

			.overlay{
				display: none;
			}
		}
	}

	.infoFour{
		.background{
			background-image:url('../img/social_hero_feature.jpg');
		}
	}

	.media{
		.mediaContainer{
			.imageWrapper{
				display: none;
			}	
		}
	}
	
	.stats{
		.statsContainer{
			.statsItem{
				.headline{
					span{
						color: #BA0060;
					}
				}
			}
		}
	}
}
