.stats{
	
	.item;

	background-color: @darkerGrey;
	
	color:white;
	text-align: center;

	.statsContainer{
			padding-bottom: 20px;
    		padding-top: 20px;

		@media @768{
			padding-bottom: 50px;
    		padding-top: 50px;
		}
		.statsItem{
			
			text-align: left;
			opacity: 1;
			
			padding:20px;
			box-sizing: border-box;

			opacity: 1;


			@media @768{	
				max-width: 465px;
				display: inline-block;
				vertical-align: text-top;
			}

			@media @1366{
			    max-width: 320px;
			    padding: 50px;
			}

			.icon{
				.ccnr;
				height: 100px;
		    	width: 100px;
		    	margin: 0 auto;
		    	margin-bottom: 20px;
			}
			
			.headline{
				color:white;
				text-transform: uppercase;
				font-size: 23px;
				line-height: 25px;
				letter-spacing: 2px;
				text-align: center;
				width: 220px;
   				margin: 0 auto;

				span{
					color: @pressYellow;
				}
			}
		}	
	}
}