.seances{

	.top{
		.background{
			background-image:url('../img/seances_mid_casestudy.jpg');
		}
	}

	.infoTwo{
		.background{
			background-image:url('../img/seances_heroMid_casestudy.jpg');
		}
	}
	
	.infoThree{
		.background{
			background-image:url('../img/seances_hero_casestudy.jpg');
		}
	}

	.media{
		.mediaContainer{
			.imageWrapper{
				display: none;
			}	
		}
	}

	.stats{
		.statsContainer{
			.statsItem{
				.headline{
					span{
						color: @pink;
					}
				}
			}
		}
	}

	.press{
		.pressContainer{
			.pressItem{
				.title{
					h2{
						color: @pink;
					}
				}
			}	
		}
	}
}