.goldStandard{
	font-family: DINCondensed-Bold;
	-webkit-font-smoothing: antialiased;
	.top{
		height: 300px;
		.background{
			.at2x('../img/emmy_hero.jpg', 1920px, 600px);
			.cover;
			.ccnr;
			
			.overlay{
				.layer;
				background-color: rgba(0,0,0,0.5);
			}
		}

		.icon{
			.layer;
			font-size: 20px;
			color: white;
		}

		.content{
			display: table;
			.innerContent{
				display: table-cell;
				vertical-align: middle;
				
				h1{
					opacity: 0.6;
					font-size: 50px;
					line-height: 42px;	
					color: white;
					text-transform: uppercase;
					font-family: DINCondensed-Bold;
					width: 260px;
					margin-left: 15px;
					margin-top: 80px;

					@media @768{
						font-size: 90px;
						line-height: 72px;
						margin-left: 35px;
						width: 618px;
					}

					@media @1024{
						font-size: 120px;
					    line-height: 102px;
					    margin-left: 35px;
					    width: 848px;
					}

				}

				.rule{
					width: 260px;
					border-bottom: 1px solid white;
    				margin-top: 0px;
					margin-bottom: 5px;

					@media @768{
						width: 600px;
					}

					@media @1024{
						width:790px;
					}

				}	
			}
		}
	}

	.question{
		color: white;
		 box-sizing:border-box;

		.content{
			display: table;
			.innerContent{
				display: table-cell;
				vertical-align: middle;

				.text{
					margin: 0 auto;
					width: 240px;
					padding-top: 50px;
					padding-bottom: 50px;
					width: 100%;

					@media @768{
						padding-top: 80px;
						padding-bottom: 80px;
					}

					@media @1366{
						padding-top: 100px;
						padding-bottom: 100px;
					}

					h1{
						font-family: DINCondensed-Bold;
						text-transform: uppercase;
					    font-size: 35px;
					    text-align: left;
					    letter-spacing: 3px;
						line-height: 30px;
		    			padding-left: 35px;
						padding-right: 35px;
						width: 280px;

						@media @768{
							width: 470px;
							padding-left: 50px;
							padding-right: 50px;
						}

						@media @1024{
							 width: 720px;
						}

						@media @1366{
							padding-right: 200px;
							padding-left: 200px;
						}
					}

					p{
		   				.dinLight;
		   				font-size: 14px;
		   				padding-left: 35px;
						padding-right: 35px;
						margin-top: 20px;

						@media @768{
							font-size: 18px;
							padding-left: 50px;
							padding-right: 50px;
							width: auto;
						}

						@media @1366{
							padding-right: 200px;
							padding-left: 200px;
						}
					}

					.cta{
						cursor: pointer;
						//margin: 0 auto;
						width: 240px;
						color: #d6a12f;
						padding-top: 20px;
						padding-left: 35px;
						padding-right:35px;
						width: 240px;


						@media @768{
							padding-left: 50px;
							padding-right: 50px;
							width: 100%;
						}

						@media @1366{
							padding-right: 200px;
							padding-left: 200px;
						}


						#gold{
							label{
								font-size: 22px;
							    padding-right: 10px;
							    text-transform: uppercase;
							    color: white;
							    letter-spacing: 1px;
							    display: block;
							}
		
							.submit{
								margin-top: 20px;
								color: #d6a12f;
							    background: transparent;
							    border: 1px solid #d6a12f;
							    text-transform: uppercase;
							    font-size: 24px;
								padding-top: 10px;
								padding-bottom: 5px;
								padding-left: 10px;
								padding-right: 10px;
								letter-spacing: 1px;
							    font-family: DINCondensed-Bold;
							    cursor: pointer;
							    text-indent: 0px;
							    display: none;
							}

							input{
								background-color: transparent;
								font-size: 18px;
								color:  white;
								text-indent: 15px;
								border: 1px solid white;
							    background: transparent;
							    height: 30px;
							    width: 260px;
								.dinLight;

								&.invalid{
									border:1px solid #ba0909;
								}
							}

							.inputName{
							    margin-bottom: 10px;
							}
						}

						

						#thanks{
							display: none;
							font-family: DINCondensed-Bold;
							text-transform: uppercase;
							font-size: 24px;
							letter-spacing: 2px;
						}
						.generateBtn{
							display: block;
							position: relative;
							margin-top: 20px;
							color: #d6a12f;
						    background: transparent;
						    border: 1px solid #d6a12f;
						    text-transform: uppercase;
						    font-size: 24px;
							padding-top: 10px;
							padding-bottom: 5px;
							padding-left: 10px;
							padding-right: 10px;
							letter-spacing: 1px;
						    font-family: DINCondensed-Bold;
						    cursor: pointer;
						    text-indent: 0px;
						    width: 240px;
						    height: 30px;
						    .bgBtn{
						        position: absolute;
						        bottom: 0;
						        width: 0px;
						        height: 100%;
						        background-color: #d6a12f;
						        z-index: 1;
						        transition:width 300ms @easeInQuad;
						        left: 0;
						    }
						
						    &.hover{
						        color: #000;
						        .bgBtn{
						            width: 100%;
						        }
						    }

						    .textBtn{
						        position: absolute;
							    font-family: DINCondensed-Bold;
						        z-index: 2;
						        width: 100%;
						        height: 100%;
						        transition:color 200ms @easeOutQuad;

						    }

						}
					}
				}
			}
		}
	}

	.even{
		.background{
			background-color: @darkerGrey;
		}
	}

	.odd{
		.background{
			background-color: @darkerGrey;
		}
	}
}