
.howitworks{
	-webkit-font-smoothing: antialiased;
		.top{
			.background{
				.at2x('../img/howitworks_hero_destiny.jpg', 1920px, 1080px);
				.cover;
				.ccnr;
			
				.overlay{
					.layer;
					background-color: rgba(0,0,0,0.6);
				}
			}

		.content{
			display: table;
			.innerContent{
				display: table-cell;
				vertical-align: middle;
				margin-left: 15px;

				
				h1{
					opacity: 0.6;	
					font-size: 60px;
					color: white;
					line-height: 48px;
					text-transform: uppercase;
					font-family: DINCondensed-Bold;
					width: 260px;
					margin-left: 15px;
					margin-top: 55px;

					@media @768{
					 	margin-left: 35px;
				    	font-size: 90px;
					    width: 635px;
					    line-height: 69px;
					}

					@media @1366{
						margin-left: 35px;
					    font-size: 120px;
					    width: 855px;
					    line-height: 96px;
					}

				}

				.rule{
					border-bottom: 1px solid white;
					width: 280px;

					@media @768{
						width: 630px;
					}

					@media @1366{
						width:820px;
					}
				}

				h2{
					color:white;
					font-size: 17px;
		    		letter-spacing: 2px;
		    		font-family: DINCondensed-Bold;
		    		text-transform: uppercase;
		    		margin-left: 15px;


					@media @768{
						margin-left: 35px;
					}
				}

				.ctaContainer{
					cursor: pointer;
					margin-left: 20px;
					margin-top: 10px;

					@media @768{
						margin-left: 40px;
					}

					.cta{
						text-transform: uppercase;
						font-size: 17px;
						color: @orange;
						font-family: DINCondensed-Bold;
						display: inline-block;
						letter-spacing: 2px;
					}

					.play{
						color: @orange;
		    			font-size: 16px;
		    			display: inline-block;
		    			margin-left: 5px;
				
					}
					&:hover{
						.play{
							.fa{
								animation: arrowHover 0.55s @easeInOutQuad 0.1s 1 forwards;
							}
						}
					}
				}
			}
		}
	}

	.text{
		.background{
			background-color: @orange;
		}

		.content{
			display: table;
			.innerContent{
				display: table-cell;
				vertical-align: middle;
				h1{
					font-family: DINCondensed-Bold;
					text-transform: uppercase;
				    font-size: 51px;
				    line-height: 43px;
				    text-align: left;
				    letter-spacing: 3px;
				    margin-left: 15px;
				    width: 268px;
				    color: #fff;

	    			@media @768{
    					margin-left: 35px;
	    			}

				    @media @1024{
				    	font-size: 90px;
    					line-height: 70px;

				    }

				    @media @1366{
				    	font-size: 120px;
    					line-height: 90px;
				    }

				}


				.rule{
					border-bottom: 1px solid white;
				    margin-bottom: 5px;
				    width: 250px;

					@media @1024{
						width: 214px;
					}

					@media @1366{
						width: 264px;
					}
				}

				.ctaContainer{
					margin-left: 17px;

					@media @768{
						margin-left: 37px;
					}
					.cta{
						color:white;
						font-size: 17px;
			    		letter-spacing: 2px;
			    		font-family: DINCondensed-Bold;
			    		text-transform: uppercase;
			    		display: inline-block;

					}
				}
			}
		}
	}

	.step{
		.background{
			background-color: @darkGrey;
		}

		.content{
			display: table;
			.innerContent{
				display: table-cell;
				vertical-align: middle;
				
				h1{
					font-family: DINCondensed-Bold;
					text-transform: uppercase;
				    font-size: 43px;
    				text-align: left;
				    letter-spacing: 3px;
				    margin-left: 15px;
				    width: 228px;
				    color: #fff;
				    line-height: 37px;


	    			@media @768{
		   				margin-left: 35px;
		   				width: 360px;
		   			}

		   			@media @1366{
		   				font-size: 70px;
    					line-height: 60px;
    					width: 560px;
		   			}

				}


				.rule{
					border-bottom: 1px solid white;
					margin-top: 5px;
					margin-bottom: 10px;
					width: 203px;

					@media @768{
						//width: 223px;
					}

					@media @1024{
						//width: 288px;
					}
				}

				h2{
					color:white;
					font-size: 17px;
		    		letter-spacing: 2px;
		    		font-family: DINCondensed-Bold;
		    		text-transform: uppercase;
		    		margin-left: 15px;
		    		display: inline-block;

		    		@media @768{
		   				margin-left: 35px;
		   			}

				}

				p{
					.dinLight;
					margin-left: 15px;
					color: white;
		   			font-size: 14px;
		   			width: 270px;

		   			@media @768{
		   				margin-left: 35px;
		   				width: 300px;
		   			}

		   			@media @960{
		   				width: 400px;
		   				font-size: 17px;
		   			}

		   			@media @1366{
		   				width: 550px;
		   			}
				}
				
			}
		}
	}

	.create{
		.background{
			background-color: @darkerGrey;

			@media @768{
				background-color: @darkGrey;
			}

			.number{
				.layer;
				.ccnr;
				opacity: 0.1;
				background-image: url("../img/number_one_white.svg"); 
			}
		}
	}

	.edit{
		.background{
			background-color: @darkGrey;

			.number{
				.layer;
				.ccnr;
				opacity: 0.1;
				background-image: url("../img/number_two_white.svg"); 
			}
		}
	}

	.develop{
		.background{
			background-color: @darkerGrey;

			.number{
				.layer;
				.ccnr;
				opacity: 0.1;
				background-image: url("../img/number_three_white.svg"); 
			}

		}
	}

	.launch{
		.background{
			background-color: @darkGrey;

			@media @768{
				background-color: @darkerGrey;
			}

			.number{
				.layer;
				.ccnr;
				opacity: 0.1;
				background-image: url("../img/number_four_white.svg"); 
			}
		}
	}

	.review{
		.background{
			background-color: @darkerGrey;

			@media @768{
				background-color: @darkGrey;
			}

			.number{
				.layer;
				.ccnr;
				opacity: 0.1;
				background-image: url("../img/number_five_white.svg"); 
			}
		}
	}

	.testimonials{
		.background{
			background-color: @orange;

			.quoteLeft{
				background-image: url("../img/quote_left.svg"); 
				.layer;
				opacity: 0.1;
				background-repeat: no-repeat;
				width: 200px;
				height: 200px;

				@media @768{
					width: 310px;
					height: 310px;
				}

				@media @1024{
					width: 430px;
					height: 430px;
				}
			}

			.quoteRight{
				background-image: url("../img/quote_right.svg"); 
				position: absolute;
				opacity: 0.1;
				background-repeat: no-repeat;
				width: 200px;
				height: 200px;
				top: 190px;
    			right: 0;

    			@media @768{
    				top: 0;
    				width: 310px;
    				height: 310px;
    			}

    			@media @1024{
					width: 430px;
					height: 430px;
				}
			}

		}

		.content{
			display: table;
			.innerContent{
				display: table-cell;
				vertical-align: middle;

				.quote{
					text-transform: uppercase;
					font-family: DINCondensed-Bold;
					color: white;
					margin-left: 15px;
					letter-spacing: 2px;

					@media @768{
						margin-left: 35px;
					}

					.name{
						font-size: 18px;
						color: @darkerGrey;

						@media @768{
							font-size: 16px;
    						line-height: 35px;
						}


						@media @960{
							line-height: 50px;
    						font-size: 22px;
						}

						@media @1024{
							line-height: 10px;
						}

						@media @1366{
							line-height: 45px;
    						font-size: 22px;
						}
					}
				}					
				
				.left{
					//float: left;
					margin-bottom: 40px;

					@media @768{
						margin-bottom: 0px;
					}

					h1{	
						font-size: 26px;
    					line-height: 50px;

						@media @768{
							font-size: 35px;
    						line-height: 56px;	
						}

						@media @960{
							font-size: 36px;
						}

						@media @1024{
							font-size: 50px;
						}
					}

					h2{
					    font-size: 59px;
    					line-height: 31px;

						@media @768{
							margin-bottom: 10px;
						}

						@media @1024{
							font-size: 82px;
							line-height: 61px;
						}
					}

				}

				.right{
					//float: right;
					text-align: right;
					margin-right: 15px;

					h1{	
						font-size: 26px;
    					line-height: 25px;

						@media @768{
							font-size: 35px;
							line-height: 51px;	
						}

						@media @960{
							font-size: 36px;
						}

						@media @1024{
							font-size: 50px;
						}
					}

					h2{
						font-size: 59px;
    					line-height: 50px;

    					@media @1024{
    						font-size: 100px;
    						line-height: 76px;
    					}
					}

					h3{
						font-size: 26px;
    					line-height: 25px;

						@media @768{
							font-size: 35px;
							line-height: 35px;				
						}

						@media @960{
							font-size: 36px;
						}

						@media @1024{
							font-size: 50px;
							margin-bottom: 20px;
						}
					}


					@media @768{
						margin-right: 35px;
					}

				}

			}
		}
	}


	.bottom{
		height: 1020px;

		@media @768{
			height: 570px;
		}
		.background{
			background-color: @darkerGrey;
			

			.hex{
				// .layer;
				background-image: url("../img/hex_orange.svg");
				opacity: 0.6;
				.ccnr;
				width:100%;
				height: 100%;
				@media @768{
					opacity: 0;
				}

				&.showHex{
					opacity: 0.6;
					transition:opacity 1000ms @easeOutQuad;
				}
			}
		}

		.content{
			display: table;
			.innerContent{
				display: table-cell;
				vertical-align: middle;
				.left{

					h1{
						font-family: DINCondensed-Bold;
						text-transform: uppercase;
						margin: 0 auto;
						font-size: 60px;
						margin-top: 100px;
		    			width: 320px;
		    			line-height: 48px;
		    			margin-left: 15px;
		    			letter-spacing: 2px;
		    			color: white;

		   				@media @768{
						    font-size: 60px;
   							line-height: 43px;
						    margin-left: 50px;
						    margin-top: 20px;
						    width: 455px;
		    			}

		    			@media @960{
		    				font-size: 100px;
		    				width: 660px;
		    			}

		    			@media @1366{
		    				margin-top: 130px;
		    			}
					}

					.rule{
						width: 255px;
						border-bottom: 1px solid white;

						@media @768{
							width: 437px;
						}

						@media @960{
							width: 668px;
							margin-top: 10px;
						}
					}

					.arrow{
						color: #3588BA;
		    			font-size: 15px;
		    			display: inline-block;
		    			margin-left: 5px;
					}

					@media @768{
						margin-top: 20px;
					}
				}

				.right{
					width: 310px;
					margin: 0 auto;
					margin-top: 20px;

					@media @768{
						//float: right
						margin: 0 auto;
						width: 675px;
						margin-top: 15px;
					}

					@media @960{
						margin-top: 20px;
					}

					@media @1024{
						width:755px;
					}

					@media @1366{
						width: 835px;
						margin-top: 50px;

					}

					.benefits{
						.benIcon{
							position: relative;
							margin-left: 15px;
					    	.ccnr;
						}
						
						.dev{
							.benIcon{
								height: 55px;
					    		width: 55px;
								background-image: url("../img/fast.svg");
							}
						}

						.flex{
							.benIcon{
								height: 55px;
					    		width: 55px;
								background-image: url("../img/flexibility.svg");
							}
						}

						.quality{
							.benIcon{
								height: 55px;
					    		width: 55px;
								background-image: url("../img/award.svg");
							}
						}

						.mass{
							.benIcon{
								height: 55px;
					    		width: 55px;
								background-image: url("../img/masses.svg");
							}
						}

						.view{
							.benIcon{
								height: 50px;
					    		width: 50px;
								background-image: url("../img/views.svg");
							}
						}

						.piracy{
							.benIcon{
								height: 65px;
					    		width: 65px;
								background-image: url("../img/shield.svg");
							}
						}

						.budget{
							.benIcon{
								height: 60px;
					    		width: 60px;
								background-image: url("../img/pay.svg");
							}
						}

						.rapid{
							.benIcon{
								height: 55px;
					    		width: 55px;
								background-image: url("../img/protoyping.svg");
							}
						}

						.benItems{
							margin-bottom: 5px;
							margin-top: 5px;
							display: inline-block;

							@media @768{
								margin-bottom: 5px;
								margin-top: 5px;
							}

							@media @1024{
								margin-right: 10px;
								margin-left: 10px;
								margin-bottom: 20px;
							}

							@media @1366{
								margin-right: 20px;
								margin-left: 20px;
							}

							
							h1{
								font-size: 19px;
							    margin-top: 10px;
							    width: 133px;
    							line-height: 20px;
							    color: white;
							    font-family: DINCondensed-Bold;
								text-transform: uppercase;
								margin-left: 15px;
								letter-spacing: 2px;
							}

							p{
								.dinLight;
								color: white;
			   					font-size: 14px;
		    					margin-left: 15px;
		    					width: 130px;

		    					@media @768{
		    						width: 150px;
		    					}
							}

							span{
								font-size: 45px;
								color:white;
								text-align: left;
								margin-left: 20px;
							}
						}
					}
				}
			}
		}
	} 
    
}