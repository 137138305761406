.mobileMenu{
    .layer;
    position: fixed;
    top:0px;
    transition: 800ms @easeOutQuint;
    transform:translateY(0);
    overflow: hidden;
    z-index: 100;
    &.closed{
        transform:translateY(-100%);
    }
    @media @768{
        display: none;
    }
    .bg{
        pointer-events: all;
        .layer;
        background-color: rgba(0,0,0,0.9);
    }
    .content{
        font-family: DINCondensed-Bold;
        position: absolute;
        top: 70px;
        color: white;
        width: 100%;
        height: 100%;
        nav{
            margin-top: 0px;
            text-align: center;
            .linkHolder{
               height: 55px;
               display: table;
               width: 100%;
                a{
                    font-size: 25px;
                    letter-spacing: 2px;
                    text-decoration: none;
                    display: table-cell;
                    color: white;
                    text-transform: uppercase;
                    vertical-align: middle;
                }
            }
        }
    }
}