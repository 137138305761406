.ihg{
    
    .top{
        .background{
            background-image:url('../img/ihg_hero_1.jpg');
        }
    }

    .infoTwo{
        .background{
            background-image:url('../img/ihg_hero_2.jpg');
        }
    }
    
    .infoThree{
        .background{
            background-image:url('../img/ihg_hero_3.jpg');
        }
        .content{
            .innerContent{
                .text{
                    p{
                        @media @768{
                            padding-left: 20%;
                            padding-right: 20%;
                            font-size: 22px;
                            line-height: 28px;
                        }

                        @media @1024{
                            font-size: 22px;
                            line-height: 28px;
                        }
                    }
                }
            }
        }
    }

    .media{
        .mediaContainer{
            .imageWrapper{
                display: block;
                .first{
                    display: none;
                    .bg{
                        background-image:url('../img/ihg_thumbnail_en.jpg');
                    }
                }

                .second{
                    display: inline-block;
                    .bg{
                        background-image:url('../img/ihg_thumbnail_jp.jpg');
                    }
                }

                .third{
                    display: inline-block;
                    .bg{
                        background-image:url('../img/ihg_thumbnail_de.jpg');
                    }
                }

                .fourth{
                    display: inline-block;
                    .bg{
                        background-image:url('../img/ihg_thumbnail_ru.jpg');
                    }
                }
            }   
        }
    }

    .stats{
        .statsContainer{
            .statsItem{
                .headline{
                    span{
                        color: @ihgOrange;
                    }
                }
            }
        }
    }
}